import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import RuleItem from './RuleItem'
import { getUid } from '../../../../plugins/utils'
import { getExamTemplateFieldList } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import { Empty } from 'antd'
import { FieldTypeMap, RelationTypeMap, RuleLogicMap } from '../../../../common/common'
import dayjs from '@plugins/local-dayjs'

const RuleTree = forwardRef((props, ref) => {
  const [ruleTree, setRuleTree] = useState({ code: 'root', children: [] })
  const [fieldList, setFieldList] = useState([])

  useEffect(() => {
    getExamApplyFieldList()
  }, [])

  useImperativeHandle(ref, () => {
    return {
      addRule,
      resetRules,
      getRuleTree,
      generateRuleTreeData
    }
  })

  const generateRuleTreeData = (positionItem) => {
    if (positionItem.autoCheckFields && positionItem.autoCheckFields.length > 0) {
      setRuleTree({ code: 'root', key: 'generateRuleTreeData', children: generateRuleItemData(positionItem.autoCheckFields, 'root')})
    }
  }

  const generateRuleItemData = (list, parentCode) => {
    return list.map(item => {
      const code = getUid()

      return {
        parent: {
          code: parentCode,
          label: item.parent.label,
          dictValue: item.parent.dictValue
        },
        code: code,
        formData: {
          fieldCode: item.fieldCode,
          fieldName: item.fieldName,
          fieldType: item.fieldType,
          fieldJoinSelect: item.fieldJoinSelect,
          contentLogicType: item.contentLogicType,
          ruleLogicType: item.ruleLogicType,
          ruleName: item.fieldName,
          selectContentName: getSelectContentName(item),
          selectContentItems: getSelectContentItems(item)
        },
        children: item.childRule?.length > 0
          ? generateRuleItemData(item.childRule, code)
          : []
      }
    })
  }

  const getSelectContentName = (item) => {
    let name

    switch (item.fieldType) {
      case FieldTypeMap.input:
        name = item.selectContent.map(data => {
          return data.value
        }).join('、')
        break
      case FieldTypeMap.select:
        name = item.selectContent.map(data => {
          return data.label
        })
        break
      case FieldTypeMap.dateRangePicker:
        if (item.selectContent) {
          const dateArr = item.selectContent[0].value.split(',')
          name = dateArr.map(date => {
            return dayjs(date || new Date())
          })
        }

        break
      case FieldTypeMap.cascader:
        name = item.selectContent && JSON.parse(item.selectContent[0].value).map(data => {
          return data.name
        })
        break
    }

    return name
  }

  const getSelectContentItems = (item) => {
    let selectContent

    switch (item.fieldType) {
      case FieldTypeMap.cascader:
        selectContent = item.selectContent && JSON.parse(item.selectContent[0].value).map(data => {
          return {
            ...data,
            label: data.name,
            value: data.code
          }
        })
        break
    }

    return selectContent
  }

  const getExamApplyFieldList = () => {
    getExamTemplateFieldList({ examId: props.examInfo.id }).then(res => {
      if (res.code === ResponseCode.success && res.data.applyFormFields) {
        setFieldList(res.data.applyFormFields.filter(field => {
          return ['2', '3', '4', '5', '8', '12'].includes(field.fieldType)
        }).map(field => {
          return {
            label: field.fieldName,
            value: field.fieldName,
            field
          }
        }))
      }
    })
  }

  const deleteRuleItem = (rule) => {
    const children = ruleTree.children.filter(item => {
      return item.code !== rule.code
    })

    setRuleTree({ code: 'root', children: [...children] })
  }

  const resetRules = () => {
    setRuleTree({ code: 'root', children: [] })
  }

  const ruleUpdate = () => {
    setRuleTree(ruleTree)
  }

  const addRule = () => {
    const newRule = {
      parent: {
        code: 'root',
        label: '主规则'
      },
      code: getUid(),
      formData: {
        fieldCode: '',
        fieldName: '',
        fieldType: '',
        fieldJoinSelect: '',
        contentLogicType: RuleLogicMap.include,
        ruleLogicType: RelationTypeMap.and,
        ruleName: '',
        selectContentName: '',
        selectContentItems: []
      }
    }
    setRuleTree({ code: 'root', children: [...ruleTree.children, newRule] })
  }

  const getRuleTree = () => {
    return ruleTree
  }

  const generateRule = (tree) => {
    if (tree.children?.length > 0) {
      return tree.children.map((ruleItem) => {
        return (
          <RuleItem
            key={ ruleItem.code }
            ruleItem={ ruleItem }
            formDara={ ruleItem.formData }
            fieldList={ fieldList }
            ruleUpdate={ ruleUpdate }
            deleteRuleItem={ deleteRuleItem }
            disabled={ props.examInfo.examinationStatus > 0 }
          ></RuleItem>
        )
      })
    } else {
      return <Empty/>
    }
  }

  return (
    <div ref={ ref }>
      { generateRule(ruleTree) }
    </div>
  )
})

export default RuleTree
