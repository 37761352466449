import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { DatePicker, Form, Input, Modal, Select, Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { getExamDictData } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { updateDictInfo } from '../../store/slice/dict-slice'
import dayjs from '@plugins/local-dayjs'

const EditTableFormFieldModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [formItems, setFormItems] = useState([])

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (props.modalType === 'create') {
        setModalTitle(`新增${ props.editItem.field?.fieldName }`)
      } else if (props.modalType === 'edit') {
        setModalTitle(`修改${ props.editItem.field?.fieldName }`)
      }

      props.editItem?.columns.forEach(item => {
        const { type, key, fieldJoinSelect } = item
        if (type === 's' && fieldJoinSelect) {
          getDict(fieldJoinSelect)
        }

        if (props.editItem?.data && props.editItem?.data[key]) {
          form.setFieldValue(key, type === 't' ? dayjs(props.editItem.data[key]) : props.editItem.data[key])
        }
      })

      setFormItems(props.editItem.columns)
    }
  }, [modalVisible])

  const getDict = (fieldJoinSelect) => {
    if (!dictInfo[fieldJoinSelect]) {
      getExamDictData({ dictCode: fieldJoinSelect }).then(res => {
        if (res.code === ResponseCode.success) {
          dispatch(updateDictInfo({ dictKey: fieldJoinSelect, dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      let params = {}
      const dateFields = formItems.filter(item => {
        return item.type === 't'
      }).map(item => {
        return item.title
      })

      Object.keys(fields).forEach(key => {
        if (dateFields.includes(key)) {
          params[key] = dayjs(fields[key]).format('YYYY-MM-DD').toString()
        } else {
          params[key] = fields[key]
        }
      })

      props.insertSuccess && props.insertSuccess({
        fieldCode: props.editItem.field.fieldCode,
        value: params
      })
      hideModal()
    })
  }

  const generateFormItem = (formItemList) => {
    return formItemList.map((item, index) => {
      return (
        <Form.Item
          label={ item.title }
          name={ item.title }
          key={ index }
          rules={ [{ required: true }] }
        >
          { generateFormItemContent(item) }
        </Form.Item>
      )
    })
  }

  const generateFormItemContent = (field) => {
    let content

    switch (field.type) {
      case 'a':
        content = <Input style={ { width: 230 } }></Input>
        break
      case 's':
        content = <Select
          style={ { width: 230 } }
          options={ dictInfo[field.fieldJoinSelect]?.map(item => {
            return {
              label: item.dictLabel,
              value: item.dictValue
            }
          }) }></Select>
        break
      case 't':
        content = <DatePicker
          format="YYYY-MM-DD"
          style={ { width: 230 } }
        ></DatePicker>
        break
    }

    return content
  }

  return (
    <div ref={ ref }>
      <Modal
        centered
        title={ modalTitle }
        open={ modalVisible }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Form
          form={ form }
          labelCol={ {
            span: 8
          } }
          wrapperCol={ {
            span: 12
          } }
        >
          { generateFormItem(formItems) }
        </Form>
      </Modal>
    </div>
  )
})

export default EditTableFormFieldModal
