import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, notification, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { downloadExamineeBatch, getExamApplyFormTableInfo, getExamDictData } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { isArray } from 'lodash/lang'
import { updateDictInfo } from '../../store/slice/dict-slice'
import { downloadFile } from '../../plugins/utils'
import { generateApplyFormTable } from '../../components/examinee/plugins/GenerateApplyFormTable'

const PrintApplyFormTableModal = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const envInfo = useSelector(state => state.env.envInfo)
  const [modalVisible, setModalVisible] = useState(false)
  const [examineeItems, setExamineeItems] = useState([])
  const [applyFields, setApplyFields] = useState([])
  const [loadedDictKeys, setLoadedDictKeys] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingText, setLoadingText] = useState('加载中...')
  const [applyTableTemplateInfo, setApplyTableTemplateInfo] = useState(null)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    validateFieldsDictLoaded()
  }, [loadedDictKeys])

  const showModal = (examineeItems) => {
    setModalVisible(true)
    setExamineeItems(examineeItems)
    setLoadedDictKeys([])
    getExamApplyFieldTemplateAction()
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const validateFieldsDictLoaded = () => {
    if (applyFields.length > 0 && applyFields.filter(field => {
      return field.fieldJoinSelect
    }).length === loadedDictKeys.length) {
      generateApplyFormTableAction()
    }
  }

  const generateApplyFormTableAction = () => {
    setLoadingText('正在生成报名表文件···')
    const tableList = examineeItems.map(examineeItem => {
      return {
        html: generateApplyFormTable(applyTableTemplateInfo.template, applyTableTemplateInfo.fieldSet, examineeItem, dictInfo, envInfo.picDomain),
        examineesName: examineeItem.name,
        applyNo: examineeItem.idCard
      }
    })

    downloadExamineeBatch({
      tableList: tableList,
      fileType: 'APPLY'
    }).then((res) => {
      downloadFile(res, 'zip', '考生报名表').catch(err => {
        console.error(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
      hideModal()
      notification.success({
        message: '下载成功'
      })
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        centered: true,
        content: '网络错误，生成文件失败，请稍后尝试'
      })
    })
  }

  const getDictInfo = (fieldJoinSelect) => {
    if (!dictInfo[fieldJoinSelect]) {
      setLoadingText('加载数据字典···')
      getExamDictData({ dictCode: fieldJoinSelect }).then(res => {
        if (res.code === ResponseCode.success) {
          dispatch(updateDictInfo({ dictKey: fieldJoinSelect, dictValue: res.data }))
          setLoadedDictKeys(pre => {
            return [].concat(pre, [fieldJoinSelect])
          })
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    } else {
      setLoadedDictKeys(pre => {
        return [].concat(pre, [fieldJoinSelect])
      })
    }
  }

  const getApplyFieldDictInfo = (fields) => {
    if (fields && isArray(fields)) {
      fields.forEach(field => {
        if (field.fieldJoinSelect) {
          getDictInfo(field.fieldJoinSelect)
        }
      })
    }
  }

  const getExamApplyFieldTemplateAction = () => {
    setLoading(true)
    setLoadingText('加载报名表模板数据···')
    getExamApplyFormTableInfo({ examId: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setApplyFields(res.data.fieldSet)
        getApplyFieldDictInfo(res.data.fieldSet)
        setApplyTableTemplateInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，获取报名表模板字段失败'
      })
    })
  }

  return (<>
    <Modal
      title={ '批量打印报名表' }
      open={ modalVisible }
      closable={ false }
      footer={ null }
    >
      <Spin spinning={ loading } tip={ loadingText }>
        <div style={ { height: '500px' } }></div>
      </Spin>
    </Modal>
  </>)
})

export default PrintApplyFormTableModal
