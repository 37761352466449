import { Watermark } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

function AppWatermark ({ children }) {
  const userInfo = useSelector(state => state.userInfo.userInfo)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [userName] = useState(userInfo.userName)
  const replaceLetter = (value, start, end) => {
    if (value) {
      const word = value.split('')
      word.fill('*', start, end)
      return word.join('')
    } else {
      return ''
    }
  }



  return (
    <Watermark
      content={[selectedExam.name, replaceLetter(userName, 4, userName.length - 4)]}>
      { children }
    </Watermark>
  )
}

export default AppWatermark
