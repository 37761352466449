import React, { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ConfigProvider, Form, Input, Modal, notification, Select, Space, Table, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { updateExamList, updateSelectedExam } from '../../store/slice/exam-info-slice'
import { deleteExam, getOrgExamList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import CreateExamModal from './create-exam-modal/CreateExamModal'
import { examinationStatusMap } from '../../common/common'
import ComponentVisibleByAuth from '../../components/ComponentVisibleByAuth'
import AppButton from '../../components/AppButton'
import router from '../../routers/router'
import EditExamNameModal from './exam-detail-drawer/EditExamNameModal'
import { throttle } from '../../plugins/utils'

function ExamManagement () {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const createExamModalRef = useRef()
  const editExamNameModalRef = useRef()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(false)
  const [currentEditItem, setCurrentEditItem] = useState({})
  const [notifyApi, contextHolder] = notification.useNotification()
  const orgList = useSelector(state => state.org.orgList)
  const siteId = sessionStorage.getItem('siteId')

  useEffect(() => {
    dispatch(updateSelectedExam({
      selectedExam: {}
    }))
  })

  useEffect(() => {
    getTableData()
  }, [pageNum, pageSize])

  const columns = [
    {
      title: '考试名称',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '考试编码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '组织机构',
      dataIndex: 'orgDetail',
      key: 'orgDetail',
      render: (record) => {
        return record?.name
      }
    },
    {
      title: '考试状态',
      width: 100,
      dataIndex: 'examinationStatus',
      key: 'examinationStatus',
      render: (record) => {
        return examinationStatusMap[record]
      }
    },
    {
      title: '操作',
      width: 160,
      dataIndex: 'operation',
      key: 'operation',
      render: (data, row) => {
        return (<>
          <Space className="d-flex justify-content-end">
            <Button
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showExamDetail) }
            >配置考试</Button>
            <Button
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditExamNameModal) }
            >修改名称</Button>
            {
              row.examinationStatus === 0
               ? (
                  <AppButton
                    authCode={ 'button_kslb-scks' }
                    type="primary"
                    danger
                    ghost
                    onClick={ openModal.bind(this, row, showDeleteExamModal) }
                  >删除</AppButton>
                )
                : (
                  <Tooltip title="仅可删除未开启报名的考试">
                    <AppButton
                      authCode={ 'button_kslb-scks' }
                      type="primary"
                      danger
                      ghost
                      disabled
                    >删除</AppButton>
                  </Tooltip>
                )
            }

          </Space>
        </>)
      }
    }
  ]

  const options = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '创建考试',
      value: 0
    },
    {
      label: '报名中',
      value: 1
    },
    {
      label: '考场安排',
      value: 2
    },
    {
      label: '成绩发布',
      value: 3
    },
    {
      label: '面试通知',
      value: 4
    },
    {
      label: '考试结束',
      value: 5
    }
  ]

  const getTableData = (allFields) => {
    const searchData = allFields || form.getFieldsValue()

    setLoading(true)
    const params = {
      orgIdStrSet: orgList.map(item => item.id),
      pageNum,
      pageSize,
      orderBy: 'id',
      orderType: 'DESC',
      ...searchData
    }

    getOrgExamList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
        dispatch(updateExamList({ examList: res.data.elements }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试列表失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageNum(num)
    setPageSize(size)
  }

  const openModal = (row, fn) => {
    setCurrentEditItem(row)
    fn && fn(row)
  }

  const showEditExamNameModal = () => {
    editExamNameModalRef.current.showModal()
  }

  const showCreateExamModal = () => {
    createExamModalRef.current.showModal()
  }

  const showExamDetail = (row) => {
    router.navigate(`/${ siteId }/exam/exam-detail?examId=${ row.id }`)
  }

  const showDeleteExamModal = (row) => {
    Modal.confirm({
      title: '删除考试',
      centered: true,
      content: (
        <div>确定要删除考试【{ row.name }】吗？</div>
      ),
      onOk () {
        deleteExamAction(row)
      }
    })
  }

  const deleteExamAction = (row) => {
    setLoading(true)
    deleteExam({ id: row.id }).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })

        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，删除考试失败'
      })
    })
  }

  const onValuesChange = (fields, allFields) => {
    setPageNum(1)
    setPageSize(10)
    getTableData(allFields)
  }

  const createSuccess = exam => {
    getTableData()
    openModal(exam, showExamDetail)
  }

  const getOrgOptions = (list) => {
    return [
      { label: '全部', value: '' },
      ...list.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })]
  }

  return (
    <div>
      { contextHolder }
      <div className="mb-3 d-flex justify-content-between">
        <Form
          layout="inline"
          form={ form }
          initialValues={
            {
              name: '',
              examinationStatus: ''
            }
          }
          onValuesChange={throttle(onValuesChange, 300)}
          autoComplete="off">
          <Form.Item
            label="考试名称"
            trigger={ 'onInput' }
            name="name">
            <Input placeholder="输入考试名称搜索" style={ { width: '180px' } }/>
          </Form.Item>
          <Form.Item
            label="组织机构"
            trigger={ 'onChange' }
            name="orgId">
            <Select options={ getOrgOptions(orgList) } placeholder="输入组织机构搜索" style={ { width: '280px' } }/>
          </Form.Item>
          <Form.Item
            label="考试状态"
            name="examinationStatus">
            <Select placeholder="输入考试状态搜索" options={ options } style={ { width: '180px' } }/>
          </Form.Item>
        </Form>

        <ComponentVisibleByAuth authCode={ 'button_kslb-cjks' }>
          <Button
            type="primary"
            icon={ <PlusOutlined/> }
            onClick={ openModal.bind(this, {}, showCreateExamModal) }
          >创建考试</Button>
        </ComponentVisibleByAuth>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record.id }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <CreateExamModal
        ref={ createExamModalRef }
        insertSuccess={ createSuccess }
      ></CreateExamModal>

      <EditExamNameModal
        ref={ editExamNameModalRef }
        examInfo={ currentEditItem }
        insertSuccess={ getTableData }
      ></EditExamNameModal>
    </div>
  )
}

export default ExamManagement
