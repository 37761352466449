import React, { useEffect, useRef, useState } from 'react'
import { Button, Descriptions, ConfigProvider, Form, Input, Modal, notification, Select, Space, Table, Upload } from 'antd'

import {
  deletePosition,
  downloadPositionTemplate,
  downloadQualificationTable,
  getExamPositionList, getExamPositionTemplate,
  getPositionFieldStatus, uploadImg, uploadPositionCheckPerson, uploadPositionTable
} from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'
import EditExamPositionModal from './EditExamPositionModal'
import ConfigCheckRuleModal from './ConfigCheckRuleModal'
import EditCheckPersonModal from './EditCheckPersonModal'
import PositionCheckModal from './PositionCheckModal'
import ComponentDisabled from '../../../../components/ComponentDisabled'
import ComponentVisibleByAuth from '../../../../components/ComponentVisibleByAuth'
import { useSelector } from 'react-redux'
import eventBus from '../../../../plugins/events'
import AppButton from '../../../../components/AppButton'
import { downloadFile, throttle } from '../../../../plugins/utils'

function SettingPosition () {
  const [form] = Form.useForm()
  const positionCheckModalRef = useRef()
  const editPositionModalRef = useRef()
  const configCheckRuleModalRef = useRef()
  const editCheckPersonModalRef = useRef()
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const envInfo = useSelector(state => state.env.envInfo)
  const [notifyApi, contextHolder] = notification.useNotification()
  const [currentEditItems, setCurrentEditItem] = useState([])
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [modalType, setModalType] = useState('')
  const [selectedTableItem, setSelectedTableItem] = useState([])
  const [loading, setLoading] = useState(false)
  const [applySiteOptions, setApplySiteOptions] = useState([])
  const [positionFieldStatus, setPositionFieldStatus] = useState(false)
  const [positionFieldTemplates, setPositionFieldTemplates] = useState([])

  const columns = [
    {
      title: '报名点名称',
      dataIndex: 'siteName',
      key: 'siteName'
    },
    {
      title: '单位名称',
      dataIndex: 'unitName',
      key: 'unitName'
    },
    {
      title: '单位代码',
      dataIndex: 'unitCode',
      key: 'unitCode'
    },
    {
      title: '职位名称',
      dataIndex: 'positionName',
      key: 'positionName'
    },
    {
      title: '职位代码',
      dataIndex: 'positionCode',
      key: 'positionCode'
    },
    {
      title: '职位复核',
      dataIndex: 'checkState',
      key: 'checkState',
      render: (record) => {
        return getPositionCheckStateLabel(record)
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: '280px',
      key: 'operation',
      render: (record, row) => {
        return (<>
          <Space className="mb-2 d-flex justify-content-end">
            <AppButton
              authCode={ 'button_ksxq-zwgl-pzshry' }
              type="primary"
              ghost
              onClick={ openModal.bind(this, row, showEditCheckPersonModal) }
            >配置审核人员</AppButton>
            {
              String(selectedExam.checkType) === '2'
                ? <AppButton
                  authCode={ 'button_ksxq-zwgl-szshtj' }
                  type="primary"
                  ghost
                  onClick={ openModal.bind(this, row, showEditCheckRulesModal) }
                >{ selectedExam.examinationStatus > 0 ? '查看' : '配置' }审核条件</AppButton>
                : <></>
            }

          </Space>
          <Space className="d-flex justify-content-end">
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <AppButton
                authCode={ 'button_ksxq-zwgl-fh' }
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showPositionCheckModal) }
              >复核</AppButton>
              <AppButton
                authCode={ 'button_ksxq-zwgl-bj' }
                type="primary"
                ghost
                onClick={ openModal.bind(this, row, showEditPositionModal) }
              >编辑</AppButton>
              <AppButton
                authCode={ 'button_ksxq-zwgl-sc' }
                type="primary"
                ghost
                danger
                onClick={ openModal.bind(this, row, showDeletePositionModal) }
              >删除</AppButton>
            </ComponentDisabled>
          </Space>
        </>)
      }
    }
  ]

  useEffect(() => {
    eventBus.addListener('reloadTableData', eventListener)

    return () => {
      eventBus.removeListener('reloadTableData', eventListener)
    }
  }, [])

  useEffect(() => {
    if (selectedExam.id) {
      getPositionTemplate()
      getPositionFieldStatusAction()
      setApplySiteOptions([
        { label: '全部', value: '' },
        ...(selectedExam.applySiteList?.map(item => {
          return {
            label: item.name,
            value: item.number
          }
        }) || [])
      ])

      form.resetFields()
    }
  }, [selectedExam])

  useEffect(() => {
    getTableData()
  }, [pageSize, pageNum, selectedExam])

  const eventListener = (id) => {
    getTableData({}, id)
  }

  const getPositionFieldStatusAction = () => {
    getPositionFieldStatus({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        setPositionFieldStatus(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，查询职位字段配置状态失败'
      })
    })
  }

  const getTableData = (allFields, examId) => {
    const searchData = allFields || form.getFieldsValue()

    setLoading(true)
    const params = {
      ...searchData,
      pageNum,
      pageSize,
      examId: examId || selectedExam.id,
      siteNumbers: !searchData.siteNumber
        ? selectedExam.applySiteList?.map(item => item.number)
        : [searchData.siteNumber]
    }
    delete params.siteNumber

    getExamPositionList(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      setLoading(false)
      Modal.error({
        title: '网络错误',
        content: '查询职位数据失败',
        centered: true
      })
    })
  }

  const openModal = (row, fn) => {
    setCurrentEditItem([row])
    fn && fn(row)
  }

  const showEditCheckPersonModal = (row) => {
    editCheckPersonModalRef.current.showModal()
  }

  const showEditCheckRulesModal = (row) => {
    configCheckRuleModalRef.current.showModal()
  }

  const showPositionCheckModal = (row) => {
    positionCheckModalRef.current.showModal([row])
  }

  const tableSelectedValidate = (fn) => {
    if (selectedTableItem.length > 0) {
      setCurrentEditItem(selectedTableItem)
      fn && fn(selectedTableItem)
    } else {
      notifyApi.warning({
        message: '警告',
        description: '未选择职位，不能进行批量操作'
      })
    }
  }

  const showBatchEditCheckRulesModal = () => {
    tableSelectedValidate(() => {
      configCheckRuleModalRef.current.showModal()
    })
  }

  const showBatchEditCheckPersonModal = () => {
    tableSelectedValidate(() => {
      editCheckPersonModalRef.current.showModal()
    })
  }

  const showBatchPositionCheckModal = () => {
    tableSelectedValidate((list) => {
      positionCheckModalRef.current.showModal(list)
    })
  }

  const showBatchDeletePositionModal = () => {
    tableSelectedValidate(() => {
      Modal.confirm({
        title: '删除职位',
        content: (
          <div>确定要批量删除职位吗？</div>
        ),
        onOk () {
          deletePositionAction(selectedTableItem.map(item => {
            return item._id
          }))
        }
      })
    })
  }

  const showCreatePositionModal = () => {
    setModalType('create')
    editPositionModalRef.current.showModal()
  }

  const showEditPositionModal = () => {
    setModalType('edit')
    editPositionModalRef.current.showModal()
  }

  const showDeletePositionModal = (row) => {
    Modal.confirm({
      title: '删除职位',
      content: (
        <div>确定要删除单位【{ row.unitName }】的职位【{ row.positionName }】吗？</div>
      ),
      onOk () {
        deletePositionAction([row._id])
      }
    })
  }

  const deletePositionAction = (positionIdList) => {
    const params = {
      examId: selectedExam.id,
      deletePosition: positionIdList
    }

    deletePosition(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功',
          description: '已删除职位'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '删除职位失败',
        centered: true
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const downloadPositionTable = () => {
    if (!positionFieldStatus) {
      Modal.error({
        title: '错误',
        content: '未配置职位字段'
      })
      return
    }

    setLoading(true)
    downloadPositionTemplate({ id: selectedExam.id }).then(res => {
      setLoading(false)
      downloadFile(res, 'xlsx', '职位模板').catch(err => {
        console.error(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
    }).catch(err => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，下载模板失败'
      })
    })
  }

  const uploadTemplateSuccess = ({ file }) => {
    const res = file.response

    if (res) {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.success({
          message: '操作成功'
        })
        getTableData()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }
  }

  const downloadPersonTable = () => {
    setLoading(true)
    downloadQualificationTable({ id: selectedExam.id }).then(res => {
      setLoading(false)
      downloadFile(res, 'xlsx', '职位审核人员模板').catch(err => {
        console.error(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '删除职位失败',
        centered: true
      })
    })
  }

  const handleSearch = (fields, allFields) => {
    getTableData(allFields)
  }

  const beforeUpload = () => {
    setLoading(true)
  }

  const positionChanged = () => {
    eventBus.emit('updateExamMonitor')
    getTableData()
  }

  const getPositionTemplate = () => {
    getExamPositionTemplate({ id: selectedExam.id }).then(res => {
      if (res.code === ResponseCode.success) {
        if (res.data.indexTemplateSet) {
          setPositionFieldTemplates(res.data.indexTemplateSet)
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询职位字段失败',
        centered: true
      })
    })
  }

  const getPositionCheckStateLabel = (checkState) => {
    switch (checkState) {
      case 2:
        return <div className={'text-primary'}>未复核</div>
      case 4:
        return <div className={'text-success'}>复核通过</div>
      default:
        return <div className={'text-danger'}>复核未通过</div>
    }
  }

  const getPositionDetailValue = (data) => {
    if (data && Array.isArray(data)) {
      return data.join('、')
    } else {
      return data
    }
  }

  const expandedRowRender = (fieldTemplateList, row) => {
    return (<div className={ 'px-3 py-1' }>
      <Descriptions title={ '职位信息详情' }>
        {
          fieldTemplateList.map(item => {
            return <Descriptions.Item label={ item.fieldName }>{ getPositionDetailValue(row[item.fieldCode]) }</Descriptions.Item>
          })
        }
        <Descriptions.Item label={ '职位复核' }>
          { getPositionCheckStateLabel(row.checkState) }
        </Descriptions.Item>
      </Descriptions>
    </div>)
  }

  return (
    <div>
      { contextHolder }

      <ConfigProvider componentSize={ 'small' }>
        <div className="mb-2 d-flex align-items-center justify-content-between">
          <div>
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <AppButton
                authCode={ 'button_ksxq-zwgl-xzmb' }
                className={ 'mr-2' }
                type="primary"
                onClick={ downloadPositionTable }
              >下载模板</AppButton>
            </ComponentDisabled>
            <ComponentVisibleByAuth authCode={ 'button_ksxq-zwgl-drzw' }>
              <ComponentDisabled
                disabledTip={ '报名已开始，禁止修改' }
                disabled={ selectedExam.examinationStatus > 0 }
              >
                <Upload
                  name={ 'file' }
                  action={ `${ envInfo.picDomain }/${ uploadPositionTable }` }
                  headers={ {
                    authorization: window.sessionStorage.getItem('access_token')
                  } }
                  disabled={ loading }
                  maxCount={ 1 }
                  data={ {
                    examId: selectedExam.id
                  } }
                  showUploadList={ false }
                  beforeUpload={ beforeUpload }
                  onChange={ uploadTemplateSuccess }
                >
                  <ComponentDisabled
                    disabledTip={ '报名已开始，禁止修改' }
                    disabled={ selectedExam.examinationStatus > 0 }
                  >
                    <Button
                      className={ 'mr-2' }
                      type="primary"
                    >导入职位</Button>
                  </ComponentDisabled>
                </Upload>
              </ComponentDisabled>
            </ComponentVisibleByAuth>

            <AppButton
              authCode={ 'button_ksxq-zwgl-shrymb' }
              className={ 'mr-2' }
              type="primary"
              onClick={ downloadPersonTable }
            >审核人员模板</AppButton>
            <ComponentVisibleByAuth authCode={ 'button_ksxq-zwgl-drshry' }>
              <Upload
                name={ 'file' }
                action={ `${ envInfo.picDomain }/${ uploadPositionCheckPerson }` }
                headers={ {
                  authorization: window.sessionStorage.getItem('access_token')
                } }
                disabled={ loading }
                maxCount={ 1 }
                data={ {
                  examId: selectedExam.id
                } }
                showUploadList={ false }
                beforeUpload={ beforeUpload }
                onChange={ uploadTemplateSuccess }
              >
                <Button
                  className={ 'mr-2' }
                  type="primary"
                >导入审核人员</Button>
              </Upload>
            </ComponentVisibleByAuth>
          </div>
          <Space>
            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              <AppButton
                authCode={ 'button_ksxq-zwgl-xzzw' }
                type="primary"
                onClick={ openModal.bind(this, {}, showCreatePositionModal) }
              >新增职位</AppButton>
            </ComponentDisabled>

            <AppButton
              authCode={ 'button_ksxq-zwgl-pzshry' }
              type="primary"
              onClick={ showBatchEditCheckPersonModal }
            >配置审核人员</AppButton>

            <AppButton
              authCode={ 'button_ksxq-zwgl-plfh' }
              type="primary"
              onClick={ showBatchPositionCheckModal }
            >批量复核</AppButton>

            <ComponentDisabled
              disabledTip={ '报名已开始，禁止修改' }
              disabled={ selectedExam.examinationStatus > 0 }
            >
              {
                String(selectedExam.checkType) === '2'
                  ? <AppButton
                    authCode={ 'button_ksxq-zwgl-szshtj' }
                    type="primary"
                    onClick={ showBatchEditCheckRulesModal }
                  >设置审核条件</AppButton>
                  : ''
              }

              <AppButton
                authCode={ 'button_ksxq-zwgl-sc' }
                type="primary"
                onClick={ showBatchDeletePositionModal }
              >删除</AppButton>
            </ComponentDisabled>
          </Space>
        </div>

        <div className={ 'my-3' }>
          <Form
            layout={ 'inline' }
            form={ form }
            initialValues={ {
              siteNumber: '',
              unitNameOrCode: ''
            } }
            onValuesChange={ throttle(handleSearch, 300) }
          >
            <Form.Item
              label={ '报名点' }
              name={ 'siteNumber' }
            >
              <Select options={ applySiteOptions } style={ { width: 180 } }></Select>
            </Form.Item>
            <Form.Item
              label={ '单位' }
              name={ 'unitNameOrCode' }
            >
              <Input placeholder={ '输入单位名称或者代码搜索' } style={ { width: 220 } }></Input>
            </Form.Item>
          </Form>
        </div>

        <Table
          loading={ loading }
          rowSelection={ {
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedTableItem(selectedRows)
            }
          } }
          size="small"
          columns={ columns }
          dataSource={ tableData.elements }
          rowKey={ (record) => record._id }
          expandable={ {
            expandedRowRender: expandedRowRender.bind(this, positionFieldTemplates)
          } }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
        ></Table>
      </ConfigProvider>

      <EditExamPositionModal
        ref={ editPositionModalRef }
        positionItem={ currentEditItems[0] }
        examInfo={ selectedExam }
        modalType={ modalType }
        insertSuccess={ positionChanged }
      ></EditExamPositionModal>

      <ConfigCheckRuleModal
        ref={ configCheckRuleModalRef }
        positionItemList={ currentEditItems }
        examInfo={ selectedExam }
        insertSuccess={ positionChanged }
      ></ConfigCheckRuleModal>

      <EditCheckPersonModal
        ref={ editCheckPersonModalRef }
        positionItemList={ currentEditItems }
        examInfo={ selectedExam }
        insertSuccess={ positionChanged }
      ></EditCheckPersonModal>

      <PositionCheckModal
        ref={ positionCheckModalRef }
        insertSuccess={ positionChanged }
      ></PositionCheckModal>
    </div>
  )
}

export default SettingPosition
