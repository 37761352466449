import { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, notification, Spin } from 'antd'
import { downloadExamineeBatch, downloadFile as downloadFileByPath, getExamineeInterviewInfoBatch, getInterviewTemplateInfo } from '../../common/request'
import { useSelector } from 'react-redux'
import ResponseCode from '../../common/response-code'
import { downloadFile } from '../../plugins/utils'
import { generateInterviewTicket } from '../../components/examinee/plugins/GenerateInterviewTicket'

const PrintInterviewTicketModal = forwardRef((props, ref) => {
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const envInfo = useSelector(state => state.env.envInfo)
  const[modalVisible, setModalVisible] = useState(false)
  const [loadingText, setLoadingText] = useState('加载中...')
  const [loading, setLoading] = useState(true)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (examineeItems) => {
    setModalVisible(true)
    getExamApplyFieldTemplateAction(examineeItems)
  }

  const getExamApplyFieldTemplateAction = (examineeList) => {
    setLoadingText('正在查询面试准考证模板信息...')
    setLoading(true)
    Promise.all([
      getExamineeInterviewInfoBatch({
        examId: selectedExam.id,
        idCards: examineeList.map(item => item.idCard)
      }),
      getInterviewTemplateInfo({ examId: selectedExam.id })
    ]).then(([infoRes, templateInfoRes]) => {
      setLoading(false)
      if (templateInfoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '面试准考证模板信息查询失败'
        })
        return
      }

      if (infoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: '考生面试准考证信息查询失败'
        })
        return
      }

      generateCertificateFile(infoRes.data, templateInfoRes.data)
    })
  }

  const generateCertificateFile = (interviewInfoList, templateInfo) => {
    setLoadingText('正在生成考生面试准考证信息...')
    const tableList = interviewInfoList.map(interviewInfo => {
      return {
        html: generateInterviewTicket(templateInfo.template, templateInfo.fieldSet, interviewInfo, envInfo.picDomain),
        examineesName: interviewInfo.name,
        applyNo: interviewInfo.idCard
      }
    })

    downloadExamineeBatch({
      tableList: tableList,
      fileType: 'INTERVIEW'
    }).then((res) => {
      downloadFile(res, 'zip', '考生面试准考证').catch(err => {
        console.error(err)
        Modal.error({
          title: '错误',
          content: err,
        })
      })
      hideModal()
      notification.success({
        message: '下载成功'
      })
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        centered: true,
        content: '网络错误，生成文件失败，请稍后尝试'
      })
    })
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  return (<>
    <Modal
      title={ '批量打印面试准考证' }
      open={ modalVisible }
      closable={ false }
      footer={ null }
    >
      <Spin spinning={ loading } tip={ loadingText }>
        <div style={ { height: '500px' } }></div>
      </Spin>
    </Modal>
  </>)
})

export default PrintInterviewTicketModal
