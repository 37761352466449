import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'antd'

const DraggableModal = ({ title, open, onCancel, children, ...rest }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragging, setDragging] = useState(false)
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0 })
  const modalWrapRef = useRef(null)
  const headerRef = useRef(null)

  useEffect(() => {
    if (open) {

    }
  }, [open])

  useEffect(() => {
    if (!headerRef.current || !open) return

    const header = headerRef.current
    const onMouseDown = (e) => {
      setDragging(true)
      setInitialPos({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      })
    }

    header.addEventListener('mousedown', onMouseDown)
    return () => header.removeEventListener('mousedown', onMouseDown)
  }, [open, position])

  useEffect(() => {
    if (!dragging) return

    const onMouseMove = (e) => {
      let newX = e.clientX - initialPos.x
      let newY = e.clientY - initialPos.y

      // 边界限制
      const modal = modalWrapRef.current?.querySelector('.ant-modal')
      if (modal) {
        const { width, height } = modal.getBoundingClientRect()
        // newX = Math.max(0, Math.min(window.innerWidth - width, newX))
        newY = Math.max(0, Math.min(window.innerHeight - height, newY))
      }

      setPosition({ x: newX, y: newY })
    }

    const onMouseUp = () => setDragging(false)

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [dragging, initialPos])

  const afterOpenChange = (open) => {
    if (open) {
      const modal = modalWrapRef.current?.querySelector('.draggable-modal')
      if (modal) {
        const { width } = modal.getBoundingClientRect()
        setPosition({
          x: (window.innerWidth - width) / 2,
          y: 30
        })
      }
    }
  }

  return (
    <div ref={ modalWrapRef }>
      <Modal
        classNames={ {
          wrapper: {}
        } }
        className="draggable-modal"
        title={
          <div
            ref={ headerRef }
            style={ { padding: '10px 20px 5px', cursor: 'move', userSelect: 'none', background: '#ecebeb', borderRadius: '8px 8px 0 0' } }
          >
            { title }
          </div>
        }
        getContainer={ () => modalWrapRef.current }
        open={ open }
        onCancel={ onCancel }
        mask={ false }
        maskClosable={ false }
        styles={ {
          wrapper: {
            pointerEvents: 'none'
          }
        } }
        style={ {
          position: 'absolute',
          top: position.y,
          left: position.x,
          margin: 0
        } }
        { ...rest }
        afterOpenChange={ afterOpenChange }
      >
        { children }
      </Modal>
    </div>
  )
}

export default DraggableModal
