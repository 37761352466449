import React, { useEffect, useState } from 'react'
import { Modal, Empty, Button, Spin } from 'antd'

import Styles from './ExamineeInfoModal.module.scss'
import { convertPdfAndDownload, examInterviewInfoStatus, findExamInterviewInfo, getExamTemplateFieldList } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { downloadPdf } from '../../plugins/utils'
import { useSelector } from 'react-redux'
import { generateInterviewTicket } from './plugins/GenerateInterviewTicket'

function ExamineeInterviewAdmission (props) {
  const envInfo = useSelector(state => state.env.envInfo)
  const [admissionTicketContent, setAdmissionTicketContent] = useState('')
  const [examMonitorStatus, setExamMonitorStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  const [initDataLoading, setInitDataLoading] = useState(true)

  useEffect(() => {
    setInitDataLoading(true)
    setAdmissionTicketContent('')
    if (props.modalVisible && props.examineeItem?.examId) {
      getInitData()
    }
  }, [props.examineeItem, props.modalVisible])

  useEffect(() => {
    if (examMonitorStatus) {
      getExamAdmissionTicketContent()
    }
  }, [examMonitorStatus])

  const getInitData = () => {
    examInterviewInfoStatus({ id: props.examineeItem?.examId }).then(res => {
      if (res.code === ResponseCode.success) {
        setExamMonitorStatus(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询考试进度失败'
      })
    })
  }

  const getExamAdmissionTicketContent = () => {
    const params = {
      examId: props.examineeItem.examId,
      idCard: props.examineeItem.idCard
    }

    Promise.all([
      getExamTemplateFieldList({ examId: props.examineeItem.examId }),
      findExamInterviewInfo(params)
    ]).then(([templateRes, infoRes]) => {
      setInitDataLoading(false)
      if (templateRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: templateRes.msg
        })
        return
      }

      if (infoRes.code !== ResponseCode.success) {
        Modal.error({
          title: '错误',
          content: infoRes.msg
        })
        return
      }

      setAdmissionTicketContent(generateInterviewTicket(templateRes.data.interviewNotice, templateRes.data.interviewNoticeFields, infoRes.data, envInfo.picDomain))
    }).catch(err => {
      console.error(err)
      setInitDataLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，初始化数据失败'
      })
    })
  }

  const getRegExp = (value) => {
    return `{{${ value }}}`
  }

  const generateImage = (path) => {
    return `<div class="img-wrap">
      <img
        src="${ path }"
        alt=".."
        style="max-width: 160px;max-height: 220px;">
      </div>`
  }

  const generateAdmissionTicketContent = (templateInfo, fieldInfo) => {
    let html = `<style>
           p {
            margin: 0;
            padding: 0!important;
          }
          table {
            margin: 0 auto;
          }
          td, tr {
            word-break: break-all!important;
            padding: 10px 0;
          }
          .inner-table {
            width: 100%;
            font-family: "宋体","Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
          }
          .inner-table tr > th {
            min-width: 60pt;
            font-size: 9pt;
            border-bottom: solid windowtext 1.0pt;
          }
          .inner-table tr > td {
            min-width: 60pt;
            font-size: 9pt;
          }
          .inner-table tr:not(:last-child) > td {
            border-bottom: solid windowtext 1.0pt;
          }
          .inner-table tr > th:not(:last-child),
          .inner-table tr > td:not(:last-child) {
            border-right: solid windowtext 1.0pt;
          }
          .img-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        </style>
        ${ templateInfo.interviewNotice || '' }
      `
    templateInfo.interviewNoticeFields?.forEach(field => {
      if (field.fieldCode === 'personalPhoto') {
        html = html.replaceAll(getRegExp(field.fieldCode), generateImage(`${ envInfo.picDomain }/${ fieldInfo[field.fieldCode] }`)) || ''
      } else {
        html = html.replaceAll(getRegExp(field.fieldCode), fieldInfo[field.fieldCode] || '')
      }
    })

    setAdmissionTicketContent(html)
  }

  const generateExamineeInterviewAdmission = (status, loading) => {
    if (loading) {
      return (<Spin spinning={ initDataLoading }>
        <div style={ { height: 300 } }></div>
      </Spin>)
    }

    if (!status.template) {
      return (<div style={ { height: 300 } }>
        <Empty description={ '考试未配置模板' }></Empty>
      </div>)
    }

    if (!status.imp) {
      return (<div style={ { height: 300 } }>
        <Empty description={ '考试未导入面试数据' }></Empty>
      </div>)
    }

    return <div className={ Styles.sampleTable } dangerouslySetInnerHTML={ { __html: admissionTicketContent } }></div>
  }

  const downloadFile = () => {
    setLoading(true)
    const params = {
      content: admissionTicketContent,
      fileType: 'INTERVIEW'
    }

    convertPdfAndDownload(params).then(res => {
      setLoading(false)
      downloadPdf(res, `${ props.examineeItem.examineesName || props.examineeItem.name }-面试准考证`)
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载失败'
      })
    })
  }

  return (
    <>
      <Spin spinning={ loading }>
        <div className={ 'my-3 d-flex justify-content-end' }>
          <Button
            type={ 'primary' }
            loading={ loading }
            onClick={ downloadFile }
          >下载</Button>
        </div>
        <div className={ Styles.fileWrap }>
          { generateExamineeInterviewAdmission(examMonitorStatus, initDataLoading) }
        </div>
      </Spin>
    </>
  )
}

export default ExamineeInterviewAdmission
