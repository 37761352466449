import React, { useEffect, useRef, useState } from 'react'
import dayjs from '@plugins/local-dayjs'
import { Button, ConfigProvider, DatePicker, Form, Input, Modal, Space, Table } from 'antd'

import { getLogRecordPage } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ViewParamsModal from './ViewParamsModal'
import DeIdentification from '../../components/DeIdentification'
import { throttle } from '../../plugins/utils'

const setDayjsTime = (dayjs, hour, minute, second) => {
  return dayjs.hour(hour).minute(minute).second(second)
}

function LogRecordApply (props) {
  const viewParamsModalRef = useRef()
  const [form] = Form.useForm()
  const [tableData, setTableData] = useState({})
  const [pageNum, setPageNum] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [queryParams, setQueryParams] = useState('')
  const defaultStartTime = setDayjsTime(dayjs(), 0, 0, 0).subtract(6, 'day')
  const defaultEndTime = setDayjsTime(dayjs(), 23, 59, 59)

  const columns = [
    {
      title: '用户名',
      key: 'userName',
      dataIndex: 'userName',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '身份证号',
      key: 'user',
      dataIndex: 'user',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'phone' }></DeIdentification>)
      }
    },
    {
      title: '操作模快',
      key: 'moduleName',
      dataIndex: 'moduleName'
    },
    {
      title: '操作内容',
      key: 'operationContent',
      dataIndex: 'operationContent'
    },
    {
      title: '操作时间',
      key: 'operationTime',
      dataIndex: 'operationTime'
    },
    {
      title: '操作参数',
      key: 'params',
      dataIndex: 'params',
      render: (record, row) => {
        return (
          <Space className={ 'd-flex justify-content-end' }>
            <Button type={ 'primary' } ghost onClick={openModal.bind(this, row.params)}>查看</Button>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    getTableData()
  }, [pageNum, pageSize])

  const getTableData = (allValue) => {
    const searchData = allValue || form.getFieldsValue()

    const params = {
      pageSize,
      pageNum,
      system: 'apply',
      ...searchData,
      startTime: searchData.dateTimeRange?.[0].valueOf(),
      endTime: searchData.dateTimeRange?.[1].valueOf()
    }

    setLoading(true)
    getLogRecordPage(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询日志列表失败'
      })
    })
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const openModal = (params) => {
    setQueryParams(params)
    viewParamsModalRef.current.showModal()
  }

  const onSearch = (value, allValue) => {
    getTableData(allValue)
  }

  return (
    <div>
      <div className={ 'my-3' }>
        <Form
          layout={ 'inline' }
          form={ form }
          initialValues={ {
            userName: '',
            user: '',
            dateTimeRange: [defaultStartTime, defaultEndTime]
          } }
          onValuesChange={ throttle(onSearch, 300) }
        >
          <Form.Item label={ '用户名' } name={ 'userName' }>
            <Input
              placeholder={ '输入用户名搜索' }
              allowClear
              style={ { width: 140 } }
            ></Input>
          </Form.Item>
          <Form.Item label={ '账号' } name={ 'user' }>
            <Input
              placeholder={ '输入账号搜索' }
              allowClear
              style={ { width: 200 } }
            ></Input>
          </Form.Item>
          <Form.Item label={ '操作模块' } name={ 'moduleName' }>
            <Input
              placeholder={ '输入操作模块搜索' }
              allowClear
              style={ { width: 200 } }
            ></Input>
          </Form.Item>
          <Form.Item label={ '时间范围' } name={ 'dateTimeRange' }>
            <DatePicker.RangePicker
              allowClear={ false }
              disabledDate={ (current) => {
                return current && current > dayjs()
              } }
              presets={
                [
                  {
                    label: '近三天',
                    value: [setDayjsTime(dayjs(), 0, 0, 0).subtract(2, 'day'), setDayjsTime(dayjs(), 23, 59, 59)]
                  },
                  {
                    label: '近七天',
                    value: [setDayjsTime(dayjs(), 0, 0, 0).subtract(6, 'day'), setDayjsTime(dayjs(), 23, 59, 59)]
                  },
                  {
                    label: '近一月',
                    value: [setDayjsTime(dayjs(), 0, 0, 0).subtract(1, 'month'), setDayjsTime(dayjs(), 23, 59, 59)]
                  },
                  {
                    label: '近三月',
                    value: [setDayjsTime(dayjs(), 0, 0, 0).subtract(3, 'month'), setDayjsTime(dayjs(), 23, 59, 59)]
                  }
                ]
              }
              showTime={ {
                hideDisabledOptions: true,
                format: 'HH:mm:ss',
                defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
              } }
              format={ 'YYYY-MM-DD HH:mm:ss' }
            ></DatePicker.RangePicker>
          </Form.Item>
        </Form>
      </div>
      <ConfigProvider componentSize={ 'small' }>
        <Table
          loading={ loading }
          columns={ columns }
          dataSource={ tableData.elements }
          pagination={ {
            showTotal: (total) => {
              return `共 ${ total } 条`
            },
            size: 'normal',
            showSizeChanger: true,
            total: tableData.totalElements,
            pageSize: pageSize,
            current: pageNum,
            onChange: paginationChange
          } }
          rowKey={ record => record.id }
        ></Table>
      </ConfigProvider>

      <ViewParamsModal
        ref={ viewParamsModalRef }
        params={ queryParams }
      ></ViewParamsModal>
    </div>
  )
}

export default LogRecordApply
