import Axios from '../plugins/axios'

export const uploadImg = 'manage/fast/dfs/file/multi/upload/1'
export const uploadFile = 'manage/fast/dfs/file/multi/upload/2'
export const uploadPersonImage = 'manage/fast/dfs/file/oneInchImage/upload'
export const updateExamineesScore = 'manage/candidate/score/uploadCandidateScore'
export const updateExamineesInterview = 'manage/examination/interview/uploadExamInterviewData'
export const uploadExamineesInterviewPhoto = 'manage/examination/interview/uploadPhotoZip'
export const uploadExaminee = 'manage/examination/certificate/uploadExamCertificateData'
export const uploadExamineeImage = 'manage/examination/certificate/uploadPhotoZip'
export const uploadPositionTable = 'manage/position/uploadPosition'
export const uploadPositionCheckPerson = 'manage/position/uploadQualificationPerson'
export const uploadNonStandardAllocateRoom = 'manage/examination/room/uploadNonStandardAllocateRoom'

export const uploadExamineesPayStatusExcel = 'manage/examination/uploadExamineesUpdatePayStatus'

export const getAppEnv = () => {
  return Axios.get('/env/detail')
}

export const getSiteData = (params) => {
  return Axios.post('/manage/area/find/code', params)
}

export const login = (params) => {
  return Axios.post('/login/manage/pwd', params)
}

export const checkerLogin = (params) => {
  return Axios.post('/login/manage/checker/pwd', params)
}

export const checkerLoginBySms = (params) => {
  return Axios.post('/login/manage/checker/sms', params)
}

export const getAccountDetail = (params) => {
  return Axios.get('/manage/user/detail', params)
}

export const toggleAccount = (params) => {
  return Axios.post('/manage/user/changeAccount ', params)
}

export const getDictList = (params) => {
  return Axios.post('/manage/dict/page', params)
}

export const createDict = (params) => {
  return Axios.post('/manage/dict/insert', params)
}

export const updateDict = (params) => {
  return Axios.post('/manage/dict/update', params)
}

export const deleteDict = (params) => {
  return Axios.post('/manage/dict/delete', params)
}

export const getDictChildList = (params) => {
  return Axios.post('/manage/dict/label/page', params)
}

export const createDictChild = (params) => {
  return Axios.post('/manage/dict/label/insert', params)
}

export const updateDictChild = (params) => {
  return Axios.post('/manage/dict/label/update', params)
}

export const deleteDictChild = (params) => {
  return Axios.post('/manage/dict/label/delete', params)
}

export const createRegionDict = (params) => {
  return Axios.post('/manage/region/insert', params)
}

export const updateRegionDict = (params) => {
  return Axios.post('/manage/region/update', params)
}

export const deleteRegionDict = (params) => {
  return Axios.post('/manage/region/delete', params)
}

export const getReceivingAccountList = (params) => {
  return Axios.post('/manage/payAccount/page', params)
}

export const getRoleList = () => {
  return Axios.get('/manage/role/list')
}

export const getRoleTagList = () => {
  return Axios.get('/manage/role/list/roleTag')
}

export const getAuthList = () => {
  return Axios.get('/manage/authority/list')
}

export const createAuth = (params) => {
  return Axios.post('/manage/authority/insert', params)
}

export const editAuth = (params) => {
  return Axios.post('/manage/authority/update', params)
}

export const deleteAuth = (params) => {
  return Axios.post('/manage/authority/delete', params)
}

export const createRole = (params) => {
  return Axios.post('/manage/role/insert', params)
}

export const editRole = (params) => {
  return Axios.post('/manage/role/update', params)
}

export const editRoleAuth = (params) => {
  return Axios.post('/manage/role/grant', params)
}

export const deleteRole = (params) => {
  return Axios.post('/manage/role/delete', params)
}

export const getOrgList = (params) => {
  return Axios.post('/manage/org/list', params)
}

export const createOrg = (params) => {
  return Axios.post('/manage/org/insert', params)
}

export const orgDetail = (params) => {
  return Axios.post('/manage/org/detail', params)
}

export const updateOrg = (params) => {
  return Axios.post('/manage/org/update', params)
}

export const deleteOrg = (params) => {
  return Axios.post('/manage/org/delete', params)
}

export const getRegionList = (params) => {
  return Axios.post('/manage/region/list', params)
}

export const getRegionTree = (params) => {
  return Axios.post('/manage/region/tree', params)
}

export const getRegistrationSite = (params) => {
  return Axios.post('/manage/applySite/page', params)
}

export const getPositionList = (params) => {
  return Axios.post('/manage/position/table/page', params)
}

export const createPosition = (params) => {
  return Axios.post('/manage/position/table/insert', params)
}

export const updatePosition = (params) => {
  return Axios.post('/manage/position/table/update', params)
}

export const deletePositionFields = (params) => {
  return Axios.post('/manage/position/table/delete', params)
}

export const getTemplateFieldList = (params) => {
  return Axios.post('/manage/field/page', params)
}

export const getExamLocationList = (params) => {
  return Axios.post('/manage/examination/location/page', params)
}

export const getExamList = (params) => {
  return Axios.post('/manage/examination/pageExamination', params)
}

export const getExamProcessTime = (params) => {
  return Axios.post('/manage/examination/progress/progressTime', params)
}

export const getWorkerList = (params) => {
  return Axios.post('/manage/org/findOrganizationList', params)
}

export const createOrgWorker = (params) => {
  return Axios.post('/manage/org/insertOrganizationUser', params)
}

export const deleteOrgWorker = (params) => {
  return Axios.post('/manage/org/deleteOrganizationUser', params)
}

export const deleteApplyFormField = (params) => {
  return Axios.post('/manage/field/delete', params)
}

export const getApplySiteList = (params) => {
  return Axios.post('/manage/org/findApplySiteList', params)
}

export const createApplySite = (params) => {
  return Axios.post('/manage/applySite/insert', params)
}

export const updateApplySite = (params) => {
  return Axios.post('/manage/applySite/update', params)
}

export const deleteApplySite = (params) => {
  return Axios.post('/manage/org/applySiteDelete', params)
}

export const getOrgPayAccountList = (params) => {
  return Axios.post('/manage/org/findPayAccountList', params)
}

export const setOrgPayAccount = (params) => {
  return Axios.post('/manage/org/updatePayAccountList ', params)
}

export const getAccountList = (params) => {
  return Axios.post('/manage/user/pageAll', params)
}

export const createAccount = (params) => {
  return Axios.post('/manage/user/insertOperation', params)
}

export const updateAccount = (params) => {
  return Axios.post('/manage/user/updateAccount', params)
}

export const resetPassword = (params) => {
  return Axios.post('/manage/user/resetPassword', params)
}

export const deleteAccount = (params) => {
  return Axios.post('/manage/user/delete', params)
}

export const getOrgFinancialPerson = (params) => {
  return Axios.post('/manage/org/findFinancialUser', params)
}

export const getOrgFinancialAccount = (params) => {
  return Axios.post('/manage/org/findPayAccountList', params)
}

export const setOrgFinancialAccount = (params) => {
  return Axios.post('/manage/org/updateFinancialUser', params)
}

export const getOrgOperationAccountList = (params) => {
  return Axios.post('/manage/org/findOperationList', params)
}

export const setOrgOperationAccount = (params) => {
  return Axios.post('/manage/org/updateOperationList', params)
}

export const getOrgListByAccount = () => {
  return Axios.post('/manage/org/userOrganizationDetail')
}

export const createExam = (params) => {
  return Axios.post('/manage/examination/insertBasic', params)
}

export const deleteExam = (params) => {
  return Axios.post('/manage/examination/delete', params)
}

export const getExamDetail = (params) => {
  return Axios.post('/manage/examination/baseInfoDetail', params)
}

export const getExamInfoById = (params) => {
  return Axios.post('/manage/examination/findExamInfoByExamId', params)
}

export const getExamSubjectList = (params) => {
  return Axios.post('/manage/examination/findSubjectConfigByExamId', params)
}

export const getHistorySubjectExamList = (params) => {
  return Axios.post('/manage/examination/pageSubjectExamination', params)
}

export const updateExamTime = (params) => {
  return Axios.post('/manage/examination/updateTimeConfig', params)
}

export const createSubject = (params) => {
  return Axios.post('/manage/examination/insertSubject', params)
}

export const updateSubject = (params) => {
  return Axios.post('/manage/examination/updateSubjectConfig', params)
}

export const deleteSubject = (params) => {
  return Axios.post('/manage/examination/deleteSubject', params)
}

export const getExamTime = (params) => {
  return Axios.post('/manage/examination/timeConfigDetail', params)
}

export const getExamClass = (params) => {
  return Axios.post('/manage/examination/findSubjectsByExamId', params)
}

export const createExamClass = (params) => {
  return Axios.post('/manage/examination/insertLevel', params)
}

export const updateExamClass = (params) => {
  return Axios.post('/manage/examination/updateLevel', params)
}

export const deleteExamClass = (params) => {
  return Axios.post('/manage/examination/deleteLevel', params)
}

export const createExamSubClass = (params) => {
  return Axios.post('/manage/examination/insertMajor', params)
}

export const updateExamSubClass = (params) => {
  return Axios.post('/manage/examination/updateMajor', params)
}

export const updateExamSubClassSubject = (params) => {
  return Axios.post('/manage/examination/configMajorSubject', params)
}

export const deleteSubClass = (params) => {
  return Axios.post('/manage/examination/deleteMajor', params)
}

export const updateExamApplySiteConfig = (params) => {
  return Axios.post('/manage/examination/updateApplySite', params)
}

export const getExamPositionTemplate = (params) => {
  return Axios.post('/manage/position/template/findPositionTemplate', params)
}

export const getOrgPositionList = (params) => {
  return Axios.post('/manage/position/table/list', params)
}

export const insertExamPosition = (params) => {
  return Axios.post('/manage/position/template/insert', params)
}

export const getExamDictData = (params) => {
  return Axios.post(`/manage/dict/label/findByDictCode?dictCode=${ params.dictCode }`, params)
}

export const createExamApplySiteBatch = (params) => {
  return Axios.post('/manage/examination/insertApplySiteBatch', params)
}

export const updateExamApplySite = (params) => {
  return Axios.post('/manage/examination/updateApplySite', params)
}

export const deleteExamApplySite = (params) => {
  return Axios.post('/manage/examination/depleteApplySite', params)
}

export const getApplySiteRoleList = () => {
  return Axios.get('/manage/role/list/applySite')
}

export const getApplySiteUser = (params) => {
  return Axios.post('/manage/applySite/findApplySiteUser', params)
}

export const updateApplySiteWorkerList = (params) => {
  return Axios.post('/manage/applySite/updateApplySiteUser ', params)
}

export const getExamPositionList = (params) => {
  return Axios.post('/manage/position/page', params)
}

export const createExamPosition = (params) => {
  return Axios.post('/manage/position/insert', params)
}

export const updateExamPosition = (params) => {
  return Axios.post('/manage/position/update', params)
}

export const getApplyFormFields = (params) => {
  return Axios.post('/manage/exam/template/getApplyFormFields', params)
}

export const getExamApplyFieldList = (params) => {
  return Axios.post('/manage/field/list', params)
}

export const getExamPositionFieldList = (params) => {
  return Axios.post('/manage/position/table/list', params)
}

export const updateExamApplyFieldList = (params) => {
  return Axios.post('/manage/exam/template/insertApplyFormFields', params)
}

export const getExamTemplateFieldList = (params) => {
  return Axios.post('/manage/exam/template/findTemplateContent', params)
}

export const updatePositionsAutoRules = (params) => {
  return Axios.post('/manage/position/saveAutoCheckFields', params)
}

export const updatePositionCheckPerson = (params) => {
  return Axios.post('/manage/position/insertQualification', params)
}

export const deletePosition = (params) => {
  return Axios.post('/manage/position/delete', params)
}

export const getExamFile = (params) => {
  return Axios.post('/manage/file/page', params)
}

export const createExamFile = (params) => {
  return Axios.post('/manage/file/insert', params)
}

export const updateExamFile = (params) => {
  return Axios.post('/manage/file/update', params)
}

export const getExamFileContent = (params) => {
  return Axios.post('/manage/file/findContentHtml', params)
}

export const deleteExamFile = (params) => {
  return Axios.post('/manage/file/delete', params)
}

export const getSampleTableData = (params) => {
  return Axios.post('/manage/exam/template/findTemplateContent', params)
}

export const getTemplateFields = (params) => {
  return Axios.post('/manage/template/fields/findTemplateFields', params)
}

export const updateApplyForm = (params) => {
  return Axios.post('/manage/exam/template/updateApplyForm', params)
}

export const updateExamCertificate = (params) => {
  return Axios.post('/manage/exam/template/updateExamCertificate', params)
}

export const updateInterviewNotice = (params) => {
  return Axios.post('/manage/exam/template/updateInterviewNotice', params)
}

export const getExamCost = (params) => {
  return Axios.post('/manage/examination/cost/getExamCost', params)
}

export const updateExamCost = (params) => {
  return Axios.post('/manage/examination/cost/updateCost', params)
}

export const examMonitor = (params) => {
  return Axios.post('/manage/examination/progress/prepare', params)
}

export const findParamByExam = (params) => {
  return Axios.post('/manage/examination/test/findParamByExam', params)
}

export const examPublish = (params) => {
  return Axios.post('/manage/examination/progress/openApply', params)
}

export const getTreeData = (params) => {
  return Axios.post('/manage/dict/tree/root', params)
}

export const createApplyFormField = (params) => {
  return Axios.post('/manage/field/insert', params)
}

export const updateApplyFormField = (params) => {
  return Axios.post('/manage/field/update', params)
}

export const updateExamApplyFormField = (params) => {
  return Axios.post('/manage/exam/template/updateApplyFormFields', params)
}

export const createExaminationSite = (params) => {
  return Axios.post('/manage/examination/location/insert', params)
}

export const updateExaminationSite = (params) => {
  return Axios.post('/manage/examination/location/update', params)
}

export const createReceivingAccount = (params) => {
  return Axios.post('/manage/payAccount/insert', params)
}

export const updateReceivingAccount = (params) => {
  return Axios.post('/manage/payAccount/update', params)
}

export const deleteReceivingAccount = (params) => {
  return Axios.post('/manage/payAccount/delete', params)
}

export const deleteApplyField = (params) => {
  return Axios.post('/manage/exam/template/deleteApplyFormFields', params)
}

export const getExamineeList = (params) => {
  return Axios.post('/manage/examination/apply/page', params)
}

export const getExamineeApplyInfo = (params) => {
  return Axios.post('/manage/examination/apply/findApplyInfo', params)
}

export const getExamApplyTemplate = (params) => {
  return Axios.post('/manage/examination/apply/findApplyFormFields', params)
}

export const getExamineeApplyLog = (params) => {
  return Axios.post('/manage/examination/apply/findApplyLog', params)
}
export const getExamineeCandidateScore = (params) => {
  return Axios.post('/manage/candidate/score/findCandidateScore', params)
}

export const updateExamineeInfo = (params) => {
  return Axios.post('/manage/examination/apply/updateApplyInfo', params)
}

export const getTreeDict = (params) => {
  return Axios.post('/manage/dict/tree/findTreeByDictCode', params)
}

export const deleteExaminee = (params) => {
  return Axios.post('/manage/examination/apply/deleteApply', params)
}

export const resetExamineeApplyStatus = (params) => {
  return Axios.post('/manage/examination/apply/resetSaveStatus', params)
}

export const getExamApplyFormTableInfo = (params) => {
  return Axios.post('/manage/examination/apply/findApplyFormTemplate', params)
}

export const statisticalByClassify = (params) => {
  return Axios.post('/manage/statistics/getLevelStatistics', params)
}

export const statisticalByPosition = (params) => {
  return Axios.post('/manage/statistics/position', params)
}

export const searchPositionByRule = (params) => {
  return Axios.post('/manage/statistics/positionQuery', params)
}

export const collectFeeStatistical = (params) => {
  return Axios.post('/manage/statistics/payQuery', params)
}

export const getApplyUnitList = (params) => {
  return Axios.post('/manage/examination/apply/findPositionUnitList', params)
}

export const getApplyPositionList = (params) => {
  return Axios.post('/manage/examination/apply/findPositionList', params)
}

export const checkPage = (params) => {
  return Axios.post(`/manage/examination/apply/checkPage?checkStatus=${ params.saveStatusSet[0] }`, params)
}

export const getCheckAccountList = (params) => {
  return Axios.post('/manage/examination/check/people/page', params)
}

export const updateCheckAccount = (params) => {
  return Axios.post('/manage/examination/check/people/updateCheckPeople', params)
}

export const deleteCheckAccountBatch = (params) => {
  return Axios.post('/manage/examination/check/people/delete', params)
}

export const resetCheckAccountPassword = (params) => {
  return Axios.post('/manage/examination/check/people/resetCheckPeoplePassword', params)
}

export const updateExamineeCheckStatus = (params, userRoleTag) => {
  return Axios.post(`/manage/examination/apply/auditApplyInfo?roleTag=${ userRoleTag }`, params)
}

export const getExamineeUnit = (params) => {
  return Axios.post('/manage/examination/room/findPositionUnitList', params)
}

export const downloadExamineePhoto = (params) => {
  return Axios.post('/manage/statistics/downloadExamineesPhoto', params, {
    timeout: 1000 * 60 * 60
  })
}

export const downloadExamineeFile = (params) => {
  return Axios.post('/manage/statistics/downloadExamineesAppendix', params, {
    timeout: 1000 * 60 * 60
  })
}

export const downloadExamineeExcel = (params) => {
  return Axios.post('/manage/statistics/downloadExaminees', params)
}

export const createExaminationBatch = (params) => {
  return Axios.post('/manage/examination/room/createBatchExaminationChoose', params)
}

export const getExaminationBatchList = (params) => {
  return Axios.post('/manage/examination/room/findBatchAllocateInfo', params)
}

export const getExaminationRoomApplySite = (params) => {
  return Axios.post('/manage/examination/room/findApplySiteList', params)
}

export const getExaminationRoomSubject = (params) => {
  return Axios.post('/manage/examination/room/findExamMajors', params)
}

export const getExaminationRoomPositionList = (params) => {
  return Axios.post(`/manage/examination/room/findPositionList?id=${ params.batchId }`, params)
}

export const updateBatchFilterRule = (params) => {
  return Axios.post('/manage/examination/room/updateExaminationChoose', params, {
    timeout: 5 * 60 * 1000
  })
}

export const deleteExamBatch = (params) => {
  return Axios.post('/manage/examination/room/deleteExaminationChoose', params)
}

export const getExamineeAccountList = (params) => {
  return Axios.post('/manage/register/page', params)
}

export const getExamineeApplyRecord = (params) => {
  return Axios.post('/manage/register/applyPage', params)
}

export const updateExamineeAccount = (params) => {
  return Axios.post('/manage/register/updateRegisterInfo', params)
}

export const deleteExamineeAccount = (params) => {
  return Axios.post('/manage/register/deleteRegister', params)
}

export const examBatchDetail = (params) => {
  return Axios.post('/manage/examination/room/findAllocateVO', params)
}

export const completeExaminationBatchConfig = (params) => {
  return Axios.post('/manage/examination/room/completeExaminationChoose', params)
}

export const getExaminationLocationList = (params) => {
  return Axios.post('/manage/examination/room/findLocationList', params)
}

export const saveExamLocationInfo = (params) => {
  return Axios.post('/manage/examination/room/saveLocationChoosesBatch', params)
}

export const saveLocationChooses = (params) => {
  return Axios.post('/manage/examination/room/saveLocationChooses', params)
}

export const getExamAllocationList = (params) => {
  return Axios.post('/manage/examination/room/findExamBatchLocationDetail', params)
}

export const getExaminationAllocationProcess = (params) => {
  return Axios.post('/manage/examination/room/findExamAllocateProcess', params)
}

export const getExaminationRoomAllocationInfo = (params) => {
  return Axios.post('/manage/examination/room/findAllocateVO', params)
}

export const getExaminationAllocationSelectLocation = (params) => {
  return Axios.post('/manage/examination/room/findExamBatchOneLocationDetail', params)
}

export const saveBatchAllocationRule = (params) => {
  return Axios.post('/manage/examination/room/saveAllocateRule', params)
}

export const getExaminationAllocationLocationList = (params) => {
  return Axios.post(`/manage/examination/room/findExamOneBatchLocationDetail?batchId=${ params.batchId }`, params)
}

export const getExaminationLocationRoomList = (params) => {
  return Axios.post(`/manage/examination/room/findExamBatchLocationRoomDetail?locationId=${ params.locationId }`, params)
}

export const getExaminationLocationLastRoomList = (params) => {
  return Axios.post('/manage/examination/room/findLastRoomList', params)
}

export const mergeLocationLastRoom = (params) => {
  return Axios.post('/manage/examination/room/mergeLastRoom', params)
}

export const examinationAllocationFinish = (params) => {
  return Axios.post('/manage/examination/room/completeAllocate', params)
}

export const examinationAllocationReset = (params) => {
  return Axios.post('/manage/examination/room/resetAllocateInfo', params)
}

export const getNotAllocationExamineeNumber = (params) => {
  return Axios.post('/manage/examination/room/countBatchNoAllocateExaminees', params)
}

export const getExaminationLocationDetailList = (params) => {
  return Axios.post('/manage/examination/room/findLocationDetailList', params)
}

export const getNoAllocateExamineeByApplySite = (params) => {
  return Axios.post('/manage/examination/room/findNoAllocateApplySiteCount ', params)
}

export const getNoAllocateExamineeByMajor = (params) => {
  return Axios.post('/manage/examination/room/findNoAllocateMajorCount', params)
}

export const manualAllocateExaminee = (params) => {
  return Axios.post('/manage/examination/room/appointAllocateToLocation', params)
}

export const generateCandidateNumber = (params) => {
  return Axios.post('/manage/examination/room/generateCandidateNumber', params)
}

export const getExaminationRoomExamineeList = (params) => {
  return Axios.post('/manage/examination/room/findExamRoomExaminees', params)
}

export const downloadExaminationRoomFile = (params) => {
  return Axios.post('/manage/examination/print/downloadRoomBatch ', params)
}

export const downloadExaminationRoomExamineeAllocateFile = (params) => {
  return Axios.post('/manage/examination/print/downloadExamineesRoomBatch', params)
}

export const downloadSeatHtmlToPdfBatch = (params) => {
  return Axios.post('/manage/examination/print/downloadSeatHtmlToPdfBatch', params)
}

export const downloadTableStickersHtmlToPdfBatch = (params) => {
  return Axios.post('/manage/examination/print/downloadTableStickersHtmlToPdfBatch', params)
}

export const downloadDoorStickersHtmlToPdfBatch = (params) => {
  return Axios.post('/manage/examination/print/downloadDoorStickersHtmlToPdfBatch', params)
}

export const downloadExamineeTableBatch = (params) => {
  return Axios.get(`/manage/examination/print/downloadExamineesTable?examId=${ params.examId }`)
}

export const getDownloadFileList = (params) => {
  return Axios.post('/manage/download/center/page', params)
}

export const downloadFile = (params) => {
  return Axios.get('/manage/fast/dfs/file/download', {
    params: params,
    responseType: 'blob',
    timeout: 60 * 60 * 1000
  })
}

export const deleteDownloadCenterFile = (params) => {
  return Axios.post('/manage/download/center/delete', params)
}

export const examineeScoreList = (params) => {
  return Axios.post('/manage/candidate/score/findCandidateScoreList', params)
}

export const downloadExamineeScoreFile = (params) => {
  return Axios.post('/manage/candidate/score/downloadCandidateScore', params, {
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const examineeInterviewList = (params) => {
  return Axios.post('/manage/examination/interview/interviewExamineesPage', params)
}

export const getManualAllocationExamineeList = (params) => {
  return Axios.post('/manage/examination/apply/importPage', params)
}

export const downloadManualAllocationTemplate = (params) => {
  return Axios.get('/manage/examination/certificate/downloadExamineesImpTemplate', {
    params,
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const clearManualAllocationData = (params) => {
  return Axios.post('/manage/examination/apply/clearImportApplyInfo', params)
}

export const findExamCertificateTemplate = (params) => {
  return Axios.post('/manage/examination/apply/findExamCertificateTemplate', params)
}

export const generateSeatTable = (params) => {
  return Axios.post('/manage/examination/apply/generateSeatTableImportApplyInfo', params)
}

export const getPoorList = (params) => {
  return Axios.post('/manage/poor/apply/page', params)
}

export const updateFieldsSort = (params) => {
  return Axios.post('/manage/exam/template/updateApplyFormFields', params)
}

export const batchInsertSubject = (params) => {
  return Axios.post('/manage/examination/batchInsertSubject', params)
}

export const downloadPositionTemplate = (params) => {
  return Axios.get('/manage/position/template/downloadPositionTable', {
    params,
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const getPositionFieldStatus = (params) => {
  return Axios.post('/manage/position/template/isPositionTemplateTable', params)
}

export const downloadQualificationTable = (params) => {
  return Axios.get('/manage/position/downloadQualificationTable', {
    params,
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const publicExamAllocation = (params) => {
  return Axios.post('/manage/examination/progress/arrangeExam', params)
}

export const findPublicResultInfo = (params) => {
  return Axios.post('/manage/examination/progress/findPublicResultInfo', params)
}

export const publicResult = (params) => {
  return Axios.post('/manage/examination/progress/publicResult', params)
}

export const findInterviewInfo = (params) => {
  return Axios.post('/manage/examination/progress/findInterviewInfo', params)
}

export const publicInterview = (params) => {
  return Axios.post('/manage/examination/progress/publicInterview', params)
}

export const examinationProgress = (params) => {
  return Axios.post('/manage/examination/progress/findArrangeInfo', params)
}

export const findExamCertificateInfo = (params) => {
  return Axios.post('/manage/examination/certificate/findExamCertificateInfo', params)
}

export const examInterviewInfoStatus = (params) => {
  return Axios.post('/manage/examination/progress/findInterviewInfo', params)
}

export const findExamInterviewInfo = (params) => {
  return Axios.post('/manage/examination/interview/findExamInterviewInfo', params)
}

export const downloadInterviewTemplate = (params) => {
  return Axios.get('/manage/examination/interview/downloadExamineesImpTemplate', {
    params,
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const clearInterviewExaminees = (params) => {
  return Axios.post('/manage/examination/interview/clearImportInterviewExaminees', params)
}

export const generateInterviewTable = (params) => {
  return Axios.post('/manage/examination/interview/generateSeatTableImportInterviewNotice', params)
}

export const convertPdfAndDownload = (params) => {
  return Axios.post('/manage/examination/print/convert2PdfAndDownload', params, {
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const findApplyDetail = (params) => {
  return Axios.post('/manage/poor/apply/findApplyDetail', params)
}

export const auditPoorApply = (params) => {
  return Axios.post('/manage/poor/apply/auditPoorApply', params)
}

export const assignChecker = (params) => {
  return Axios.post('/manage/poor/apply/assignChecker', params)
}

export const poorRefund = (params) => {
  return Axios.post('/manage/poor/apply/poorRefund', params)
}

export const getAuthPages = (params) => {
  return Axios.post('/manage/authority/query', params)
}

export const getPositionInfo = (params) => {
  return Axios.post('/manage/examination/apply/findPositionDetail', params)
}

export const findForecastRoomList = (params) => {
  return Axios.post(`/manage/examination/room/findForecastRoomList?standardRoomNumber=${ params.standardRoomNumber }`, params)
}

export const downloadNonStandardAllocateRoom = (params) => {
  return Axios.get('/manage/examination/room/downloadNonStandardAllocateRoom', {
    params,
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const updateExamBasicInfo = (params) => {
  return Axios.post('/manage/examination/updateBasic', params)
}

export const updateExamName = (params) => {
  return Axios.post('/manage/examination/updateExamName', params)
}

export const getSmsCode = (params, scene = 'password') => {
  return Axios.post('/login/manage/sms/code/send/' + scene, params)
}

export const updatePwd = (params) => {
  return Axios.post('/manage/user/updatePwd', params)
}

export const findPwd = (params) => {
  return Axios.post('/manage/user/findPassword', params)
}

export const getLoginSmsCode = (params) => {
  return Axios.post('/login/manage/sms/code/send/login', params)
}

export const loginBySmsCode = (params) => {
  return Axios.post('/login/manage/sms', params)
}

export const checkerLoginBySmsCode = (params) => {
  return Axios.post('/login/manage/sms/code/send/login', params)
}

export const uploadEditorFile = (params) => {
  return Axios.post(uploadImg, params)
}

export const resetLocationAllocation = (params) => {
  return Axios.post('/manage/examination/room/resetAllocateLocation', params)
}

export const clearImportExamineeScoreData = (params) => {
  return Axios.post('/manage/candidate/score/clearCandidateScore', params)
}

export const downloadPositionQuery = (params) => {
  return Axios.post('/manage/statistics/downloadPositionQuery', params, {
    responseType: 'blob'
  })
}

export const downloadPayQuery = (params) => {
  return Axios.post('/manage/statistics/downloadPayQuery', params, {
    responseType: 'blob'
  })
}

export const getFileTemplateFieldPage = (params) => {
  return Axios.post('/manage/template/fields/page', params)
}

export const createFileTemplateField = (params) => {
  return Axios.post('/manage/template/fields/insert', params)
}

export const updateFileTemplateField = (params) => {
  return Axios.post('/manage/template/fields/update', params)
}

export const deleteFileTemplateField = (params) => {
  return Axios.post('/manage/template/fields/delete', params)
}

export const getLogRecordPage = (params) => {
  return Axios.post('/manage/log/page', params)
}

export const getToDoList = (params) => {
  return Axios.post('/manage/examination/findTodoExam', params)
}

export const getStatisticalInfo = (params) => {
  return Axios.post('/manage/statistics/positionTotal', params)
}

export const updateManualAllocateStatus = (params) => {
  return Axios.post('/manage/examination/apply/updateExamRoomArrangement', params)
}

export const getExamRoomInfoField = () => {
  return Axios.get('/manage/statistics/examRoomCodeInfo')
}

export const getExamStatusInfoFields = () => {
  return Axios.get('/manage/statistics/examStatusCodeInfo')
}

export const getManualAllocationStatus = (params) => {
  return Axios.post('/manage/examination/apply/findExamRoomArrangement', params)
}

export const getExamineePrintList = (params) => {
  return Axios.post('/manage/examination/apply/page/table', params)
}

export const getBatchExamineeApplyInfo = (params) => {
  return Axios.post('/manage/examination/apply/findCheckApplyInfoList', params)
}

export const getInterviewPrintStatusFile = (params) => {
  return Axios.post('/manage/examination/interview/interviewExaminees/file', params, {
    responseType: 'blob',
    timeout: 1000 * 60 * 5
  })
}

export const updateImportExamineeApplyInfo = (params) => {
  return Axios.post('/manage/examination/apply/updateImportApplyInfo', params)
}

export const downloadExamineeBatch = (params) => {
  return Axios.post('/manage/examination/apply/download/table', params, {
    responseType: 'blob',
    timeout: 1000 * 60 * 60
  })
}

export const getExamineeCertificateInfoBatch = (params) => {
  return Axios.post('/manage/examination/certificate/findExamCertificateInfo/batch ', params)
}

export const getExamineeInterviewInfoBatch = (params) => {
  return Axios.post('/manage/examination/interview/findExamInterviewInfo/batch', params)
}

export const getInterviewTemplateInfo = (params) => {
  return Axios.post('/manage/examination/apply/findInterviewNoticeTemplate', params)
}

export const importExamineePrintStatus = (params) => {
  return Axios.post('/manage/examination/apply/importPage/file', params, {
    responseType: 'blob',
    timeout: 1000 * 60 * 60
  })
}

export const getCreateAccountRoleList = () => {
  return Axios.get('/manage/role/list/roleTag/create')
}

export const checkPosition = (params) => {
  return Axios.post('/manage/position/checkPosition', params)
}

export const checkPositionBatch = (params) => {
  return Axios.post('/manage/position/checkPositionBatch', params)
}

export const updateInitPassword = (params) => {
  return Axios.post('/manage/user/updateInitPwd', params)
}

export const updateShowStatistic = (params) => {
  return Axios.post('/manage/examination/updateShowStatistic', params)
}

export const getExamPublicityInfo = (params) => {
  return Axios.post('/manage/examination/getShowStatistic', params)
}

export const findExamPublicityData = (params) => {
  return Axios.post('/manage/examination/findExamShowStatistic', params)
}

export const getExamPublicityData = (params) => {
  return Axios.post('/manage/user/updateAccountIdentification', params)
}

export const syncPositionInfo = (params) => {
  return Axios.post('manage/examination/syncLevelAndMajorName', params)
}

export const getOrgExamList = (params) => {
  return Axios.post('/manage/examination/pageExaminationOrg', params)
}

export const getExamListByRole = (params) => {
  return Axios.post('/manage/examination/pageExaminationByRole', params)
}

export const downloadExamStatisticExcel = (params) => {
  return Axios.post('/manage/statistics/downloadLevelStatistics', params, {
    responseType: 'blob',
    timeout: 1000 * 60 * 60
  })
}

export const getErrorData = (params) => {
  return Axios.post('/manage/error/apply/page', params)
}

export const getErrorTypeList = () => {
  return Axios.get('/manage/error/apply/types')
}

export const getErrorGroupByExam = () => {
  return Axios.get('/manage/error/apply/exam/list')
}

export const deleteErrorInfoItem = (params) => {
  return Axios.post('/manage/error/apply/delete', params)
}

export const getAnnouncementList = (params) => {
  return Axios.post('/manage/announcement/page', params)
}

export const createAnnouncement = (params) => {
  return Axios.post('/manage/announcement/insert', params)
}

export const updateAnnouncement = (params) => {
  return Axios.post('/manage/announcement/update', params)
}

export const deleteAnnouncementItem = (params) => {
  return Axios.post('/manage/announcement/delete', params)
}

export const getAnnouncementDetail = (params) => {
  return Axios.post('/manage/announcement/detail', params)
}

export const updateAnnouncementSort = (params) => {
  return Axios.post('/manage/announcement/update/sort', params)
}

export const updateAnnouncementStatus = (params) => {
  return Axios.post('/manage/announcement/update/status', params)
}
