import React, { useRef, useEffect, useState } from 'react'
import { Button, Col, ConfigProvider, Modal, Row, Spin, Steps, Table, Tabs, Tooltip } from 'antd'

import Styles from './ExamineeInfoModal.module.scss'
import ViewExamineeFileModal from '../ViewExamineeFileModal'
import { getExamDictData, getExamineeApplyLog, getPositionInfo } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { updateDictInfo } from '../../store/slice/dict-slice'
import { useDispatch, useSelector } from 'react-redux'
import { getUid } from '../../plugins/utils'
import eventBus from '../../plugins/events'

function ExamineeInfo (props) {
  const systemFields = ['applyDateTime', 'reviewer', 'reviewDate']
  const dispatch = useDispatch()
  const dictInfo = useSelector(state => state.dict.dictInfo)
  const envInfo = useSelector(state => state.env.envInfo)
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const viewExamineeFileModalRef = useRef()
  const [basicFormItems, setBasicFormItems] = useState([])
  const [filePhotoFormItem, setFilePhotoFormItem] = useState([])
  const [registerFormItems, setRegisterFormItems] = useState([])
  const [positionFormItems, setPositionFormItems] = useState([])
  const [positionInfoFormItems, setPositionInfoFormItems] = useState([])
  const [examineeStatusTableData, setExamineeStatusTableData] = useState([])
  const [operatingRecord, setOperatingRecord] = useState([])
  const [currentViewFile, setCurrentViewFile] = useState({})
  const [initDataLoading, setInitDataLoading] = useState(true)
  const [fileTableDataSource, setFileTableDataSource] = useState([])
  const [sideBarItems, setSideBarItems] = useState([])

  useEffect(() => {
    getExamineeOperatingRecord(props.examineeItem?.id || props.examineeItem?.applyInfoId)
    getPositionInfoAction(props)
    eventBus.addListener('reloadOperatingRecord', eventListener)

    return () => {
      eventBus.removeListener('reloadOperatingRecord', eventListener)
    }
  }, [props.modalVisible])

  useEffect(() => {
    setBasicFormItems([])
    setFilePhotoFormItem([])
    setRegisterFormItems([])
    setPositionFormItems([])
    responseTransform()
  }, [props.templateField])

  useEffect(() => {
    if (props.modalVisible) {
      const initExamStatus = [
        { label: '报名序号', ...getApplyNo() },
        { label: '基本信息', ...getBasicInfoStatus() },
        { label: '个人照片', ...getSelfImageUploadStatus() },
        { label: '审核状态', ...getAuditStatus() },
      ]

      if (props.templateField.fileFields?.length > 0) {
        initExamStatus.push({ label: '资料证件', ...getFileUploadStatus() })
      }

      if (selectedExam?.payType === 1) {
        initExamStatus.push({ label: '缴费状态', ...getPaymentStatus() })
      }

      setExamineeStatusTableData(initExamStatus)
    }
  }, [selectedExam, filePhotoFormItem, props.templateField, props.applyInfo, props.modalVisible])

  useEffect(() => {
    if (props.templateField.fileFields?.length > 0) {
      setSideBarItems([
        {
          key: 'fileData',
          label: '证件资料',
          children: (
            <div className={Styles.fileListWrap}>{
              fileTableDataSource?.map(field => {
                return field.fileList?.length > 0 && (
                  <div className="mt-2" key={ field.fieldCode }>
                    <div className={ Styles.fileType }>{ field.fieldName }</div>
                    <div className={ Styles.fileValue }>{ field.fileList?.map(file => {
                      return (<Tooltip title={ file.fileName } placement="left" key={ file.filePath }>
                        <div className={ Styles.fileName } onClick={ showExamineeFile.bind(this, file) }>{ file.fileName }</div>
                      </Tooltip>)
                    }) }</div>
                  </div>
                )
              })
            }</div>
          )
        },
        {
          key: 'operationLogs',
          label: '操作记录',
          forceRender: false,
          children: (
            <div className={ Styles.operatingRecord }>
              <Steps
                className="w-100"
                progressDot
                current={ operatingRecord.length }
                direction="vertical"
                items={ getOperatingRecordSteps(operatingRecord) }
              />
            </div>
          )
        }
      ])
    } else {
      setSideBarItems([{
        key: 'operationLogs',
        label: '操作记录',
        forceRender: false,
        children: (
          <div className={ Styles.operatingRecord }>
            <Steps
              className="w-100"
              progressDot
              current={ operatingRecord.length }
              direction="vertical"
              items={ getOperatingRecordSteps(operatingRecord) }
            />
          </div>
        )
      }])
    }
  }, [operatingRecord, props.templateField, fileTableDataSource])

  useEffect(() => {
    setFileTableDataSource(props.templateField.fileFields?.map(field => {
      if (props.applyInfo.applyFiles && props.applyInfo.applyFiles[field.fieldCode]) {
        return {
          ...field,
          fileList: JSON.parse(props.applyInfo.applyFiles[field.fieldCode]).map(file => {
            return file
          })
        }
      } else {
        return []
      }
    }) || [])
  }, [props.templateField, props.modalVisible, props.examineeItem, props.applyInfo])

  useEffect(() => {
      viewExamineeFileModalRef.current.hideModal()
  }, [props.examineeItem])

  const eventListener = (id) => {
    getExamineeOperatingRecord(id)
  }

  const getExamineeOperatingRecord = (id) => {
    getExamineeApplyLog({ applyInfoId: id }).then(res => {
      if (res.code === ResponseCode.success) {
        setOperatingRecord(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      Modal.error({
        title: '错误',
        content: '网络错误，查询考生操作记录失败'
      })
    })
  }

  const getPositionInfoAction = (props) => {
    getPositionInfo({ id: props.applyInfo.positionId }).then(res => {
      if (res.code === ResponseCode.success) {
        if (res.data.xl) {
          setPositionInfoFormItems((pre) => {
            return [...pre, {
              label: '学历',
              value: res.data.xl
            }]
          })
        }
        if (res.data.xw) {
          setPositionInfoFormItems((pre) => {
            return [...pre, {
              label: '学位',
              value: res.data.xw
            }]
          })
        }
        if (res.data.zymc) {
          setPositionInfoFormItems((pre) => {
            return [...pre, {
              label: '专业名称',
              value: res.data.zymc
            }]
          })
        }
        if (res.data.qttj) {
          setPositionInfoFormItems((pre) => {
            return [...pre, {
              label: '其他条件',
              value: res.data.qttj
            }]
          })
        }
      }
    })
  }

  const responseTransform = () => {
    props.templateField.fields?.forEach((item) => {
      if (item.fieldJoinSelect) {
        getDictInfo(item.fieldJoinSelect)
      }
      if (item.fieldFromType == 2 && item.fieldFilling == 2 && !systemFields.includes(item.fieldAuto)) {
        setBasicFormItems((pre) => {
          return pre.concat(item)
        })
      }
      if (item.fieldFromType == 2 && item.fieldFilling == 1) {
        if (item.fieldType == 6) {
          setFilePhotoFormItem(pre => {
            return pre.concat(item)
          })
        } else if (item.fieldType == 7) {
          if (item.selectContent && item.selectContent != '') {
            let array = item.selectContent.split('|')
            item.tableHead = []
            array.forEach(function (text) {
              item.tableHead.push({
                title: text.split('-')[0],
                key: text.split('-')[0],
                width: text.split('-')[1] === 't' ? '100px' : 'auto',
                dataIndex: text.split('-')[0]
              })
            })
          }
          setRegisterFormItems(pre => {
            return pre.concat(item)
          })
        } else {
          setRegisterFormItems(pre => {
            return pre.concat(item)
          })
        }
      }

      if (item.fieldFromType == 1) {
        setPositionFormItems(pre => {
          return pre.concat(item)
        })
      }
    })
    setInitDataLoading(false)
  }

  const getDictInfo = (fieldJoinSelect) => {
    if (!dictInfo[fieldJoinSelect]) {
      getExamDictData({ dictCode: fieldJoinSelect }).then(res => {
        if (res.code === ResponseCode.success) {
          dispatch(updateDictInfo({ dictKey: fieldJoinSelect, dictValue: res.data }))
        } else {
          Modal.error({
            title: '错误',
            centered: true,
            content: res.msg
          })
        }
      }).catch((err) => {
        console.error(err)
        Modal.error({
          title: '网络错误',
          centered: true,
          content: '查询字段类型字典失败'
        })
      })
    }
  }

  const getApplyNo = () => {
    return {
      message: props.examineeItem.applyNo || props.examineeItem.examineesNumber,
      className: 'text-success'
    }
  }

  const getBasicInfoStatus = () => {
    const basicInfoKeyMap = {
      xm: 'name',
      sfzh: 'idCard',
      lxdh: 'phone'
    }
    const status = basicFormItems.every(item => {
      return basicInfoKeyMap[item.fieldCode]
        ? props.applyInfo[basicInfoKeyMap[item.fieldCode]]
        : props.applyInfo.applyInfo[item.fieldCode]
    })

    return {
      message: status ? '已填写' : '未填写',
      className: status ? 'text-success' : 'text-danger'
    }
  }

  const getSelfImageUploadStatus = () => {
    const needPhoto = filePhotoFormItem.length > 0
    const status = props.applyInfo.applyInfo?.grzp
    let message = '无需上传'
    let className = 'text-primary'

    if (needPhoto) {
      message = status ? '已上传' : '未上传'
      className = status ? 'text-success' : 'text-danger'
    }

    return {
      message: message,
      className: className
    }
  }

  const getFileUploadStatus = () => {
    let message = '无必传文件'
    let className = 'text-primary'
    let status = true

    if (props.templateField.fileFields?.length === 0) {
      message = '无需上传文件'
    }

    if (props.templateField.fileFields?.filter(item => {
      return item.fieldIsNotBlank === '1'
    }).length > 0) {
      status = props.templateField.fileFields.filter(item => {
        return item.fieldIsNotBlank === '1'
      }).every(item => {
        return props.applyInfo.applyFiles?.[item.fieldCode]
      })
      message = status ? '已上传' : '未上传'
      className = status ? 'text-success' : 'text-danger'
    }

    return {
      message: message,
      className: className
    }
  }

  const getAuditStatus = () => {
    let message = ''
    let className = ''

    switch (props.applyInfo.saveStatus) {
      case -2:
        message = '撤回'
        className = 'text-warning'
        break
      case -1:
        message = '审核未通过' + '(未通过原因: ' + (props.applyInfo.noApprovedReason || '智能审核不通过') + ')'
        className = 'text-danger'
        break
      case 0:
        message = '尚未提交审核'
        className = 'text-primary'
        break
      case 1:
        message = '未审核'
        className = 'text-warning'
        break
      case 2:
        message = '已通过'
        className = 'text-success'
        break
    }

    return {
      message: message,
      className: className
    }
  }

  const getPaymentStatus = () => {
    let message = ''
    let className = ''

    switch (props.applyInfo.payStatus) {
      case -1:
        message = '申请退款成功'
        className = 'text-primary'
        break
      case 0:
        message = '未缴费'
        className = 'text-warning'
        break
      case 1:
        message = '已缴费'
        className = 'text-success'
        break
      case 2:
        message = '不需缴费'
        className = 'text-primary'
        break
      case 3:
        message = '贫困生免费'
        className = 'text-primary'
        break
    }

    return {
      message: message,
      className: className
    }
  }

  const generateExamineeInfo = (list, loading) => {
    if (loading) {
      return <Spin spinning={ loading }>
        <div style={ { height: 300 } }></div>
      </Spin>
    } else {
      return list.filter(item => {
        return item.fieldCode !== 'bmxh'
      }).map((item) => {
        return (
          <div className={ Styles.infoItem } key={ item.fieldCode }>
            <div className={ Styles.infoLabel }>{ item.fieldName }</div>
            <div className={ Styles.infoValue }>{ getFieldValue(item) }</div>
          </div>
        )
      })
    }
  }

  const generatePositionInfo = (list) => {
    return list.map((item) => {
      return (
        <div className={ Styles.infoItem } key={`${item.label}-${item.value}`}>
          <div className={ Styles.infoLabel }>{ item.label }</div>
          <div className={ Styles.infoValue }>{ item.value }</div>
        </div>
      )
    })
  }

  const generateExamineePhoto = (photoForm) => {
    if (photoForm.length > 0 && props.applyInfo.applyInfo?.grzp) {
      return <img className={ Styles.userPhoto } src={ `${ envInfo.picDomain }/${ props.applyInfo.applyInfo?.grzp }` }/>
    } else {
      return <></>
    }
  }

  const getOperatingRecordSteps = () => {
    return operatingRecord.map((item, index) => {
      return {
        title: item.operateTime,
        description: <div dangerouslySetInnerHTML={ {
          __html: item.logInfo
            + (item.noApprovedReason
              ? `<div class="text-danger">
                <span>不通过原因：</span>
                <span>${ item.noApprovedReason }</span>
              </div>`
              : '')
            + (item.autoCheckResults?.filter(checkItem => {
              return !checkItem.pass
            }).map(checkItem => {
              return `<div>
              <span style="color: #1677ff;">${ checkItem.fieldName }</span>
              <span style="color: #f5222d;">不满足报考条件</span>
            </div>`
            }).join('') || '')
        } } key={ index }></div>
      }
    })
  }

  const showExamineeFile = (file) => {
    setCurrentViewFile(file)
    viewExamineeFileModalRef.current.showModal()
  }


  const getExamineeApplyStatus = (list, loading) => {
    if (loading) {
      return <Spin spinning={ loading }>
        <div style={ { height: 300 } }></div>
      </Spin>
    } else {
      return list.map((item, index) => {
        return (
          <div className={ Styles.infoItem } key={ index }>
            <div className={ Styles.infoLabel }>{ item.label }</div>
            <div className={ item.className }>{ item.message }</div>
          </div>
        )
      })
    }
  }

  const generateTable = (item) => {
    return (
      <ConfigProvider componentSize={ 'small' }>
        <Table
          className={ Styles.infoValueTable}
          bordered
          columns={ item.tableHead }
          dataSource={ props.applyInfo.applyInfo[item.fieldCode] }
          rowKey={ () => {
            return `${ item.fieldCode }${ getUid() }`
          } }
          pagination={ false }
        ></Table>
      </ConfigProvider>
    )
  }

  const getFieldValue = (field) => {
    let label = ''
    if (field.fieldFromType === '1') {
      label = props.applyInfo[field.fieldCode] || props.applyInfo.applyInfo[field.fieldCode]
    }

    if (field.fieldFromType === '2' && field.fieldFilling === '2') {
      label = props.applyInfo[field.fieldAuto] || props.applyInfo.applyInfo[field.fieldCode]
    }

    if (field.fieldFromType === '2' && field.fieldFilling === '1') {
      switch (field.fieldType) {
        case '4':
          label = getDictLabel(field) || ''
          break
        case '7':
          label = generateTable(field) || ''
          break
        case '9':
          label = getTreeDataLabel(field) || ''
          break
        case '12':
          label = getRegionDataLabel(field) || ''
          break
        default:
          label = props.applyInfo.applyInfo[field.fieldCode] || ''
      }
    }

    return label
  }

  const getDictLabel = (field) => {
    return dictInfo[field.fieldJoinSelect]?.find(item => {
      return item.dictValue === props.applyInfo.applyInfo[field.fieldCode]
    })?.dictLabel
  }

  const getTreeDataLabel = (field) => {
    const data = props.applyInfo.applyInfo[field.fieldCode]
    return (data && data[data.length - 1].split(',')[1]) || ''
  }

  const getRegionDataLabel = (field) => {
    const regionData = props.applyInfo.applyInfo[field.fieldCode]
    let label = ''
    if (regionData?.province) {
      label += regionData.province.name
    }
    if (regionData?.city) {
      label += regionData.city.name
    }
    if (regionData?.district) {
      label += regionData.district.name
    }
    return label
  }

  return (
    <div className={ 'd-flex justify-content-between' }>
      <div className={ Styles.infoWrap }>
        <div className={ Styles.infoScrollWrap }>
          <div className={ Styles.infoScroll }>
            { generateExamineePhoto(filePhotoFormItem) }
            <div className={ Styles.infoTitle }>考生报考状态</div>
            <div>{ getExamineeApplyStatus(examineeStatusTableData, initDataLoading) }</div>
            <div className={ Styles.infoTitle }>职位信息</div>
            <div>{ generateExamineeInfo(positionFormItems, initDataLoading) }</div>
            <div>{ generatePositionInfo(positionInfoFormItems) }</div>
            <div className={ Styles.infoTitle }>考生基本信息</div>
            <div>{ generateExamineeInfo(basicFormItems, initDataLoading) }</div>
            <div className={ Styles.infoTitle }>考生报名信息</div>
            <div>{ generateExamineeInfo(registerFormItems, initDataLoading) }</div>
          </div>
        </div>
      </div>
      <div className={ Styles.operatingRecordWrap }>
        <Tabs defaultActiveKey="fileData" items={ sideBarItems }></Tabs>
      </div>

      <ViewExamineeFileModal
        ref={ viewExamineeFileModalRef }
        fileItem={ currentViewFile }
      ></ViewExamineeFileModal>
    </div>
  )
}

export default ExamineeInfo
