import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Image } from 'antd'
import { useSelector } from 'react-redux'
import DraggableModal from './DraggableModal'

const ViewExamineeFileModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [fileItem, setFileItem] = useState({})
  const [isPdf, setIsPdf] = useState(false)
  const envInfo = useSelector(state => state.env.envInfo)

  useImperativeHandle(ref, () => {
    return {
      showModal,
      hideModal
    }
  })

  useEffect(() => {
    setFileItem(props.fileItem)
    setIsPdf(props.fileItem?.filePath?.includes('.pdf'))
  }, [props.fileItem])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  return (
    <div ref={ ref }>
      <DraggableModal
        title={ fileItem.fileName }
        width={ 700 }
        open={ modalVisible }
        onCancel={ hideModal }
        footer={ [] }
      >
        <div>
          <div className={ 'd-flex justify-content-center' }>
            {
              isPdf ?
                <iframe
                  src={ `${ envInfo.picDomain }/${ fileItem.filePath }` }
                  style={ { width: '100%', height: '700px', borderWidth: 0 } }
                ></iframe> :
                <Image src={ `${ envInfo.picDomain }/${ fileItem.filePath }` } preview={false}></Image>
            }
          </div>
        </div>

      </DraggableModal>

    </div>
  )
})

export default ViewExamineeFileModal
