import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal, notification, Table, Transfer } from 'antd'
import { difference } from 'lodash'

import { getOrgPositionList, insertExamPosition } from '../../../../common/request'
import ResponseCode from '../../../../common/response-code'


const ConfigExamPositionFieldsModal = forwardRef((props, ref) => {
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [tableData, setTableData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const columns = [
    {
      title: '字段名称',
      dataIndex: 'fieldName',
      key: 'fieldName',
      render: (record, row) => {
        if (row.fieldLevel === 'S') {
          return (
            <div>
              <span className="text-danger">*</span>
              <span>{ record }</span>
            </div>
          )
        } else {
          return record
        }
      }
    },
    {
      title: '字段编码',
      dataIndex: 'fieldCode',
      key: 'fieldCode'
    },
    {
      title: '字段类型',
      dataIndex: 'fieldLevel',
      key: 'fieldLevel',
      render: (record) => {
        if (record === 'S') {
          return '系统字段'
        } else if (record === 'U') {
          return '自定义字段'
        }
      }
    }
  ]

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getTableData()
    }
  }, [modalVisible])

  const getTableData = () => {
    const params = {
      orgId: props.examInfo.orgId
    }

    getOrgPositionList(params).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setTableData(res.data.map(item => {
          return {
            ...item,
            disabled: item.fieldLevel === 'S'
          }
        }))


        if (props.selectedItems.length > 0) {
          setSelectedRowKeys(props.selectedItems.map(item => item.fieldCode))
        } else {
          setSelectedRowKeys((res.data).filter(item => {
            return item.fieldLevel === 'S'
          }).map(item => item.fieldCode))
        }
      }
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    const params = {
      examId: props.examInfo.id,
      positionTemplate: {
        indexTemplateSet: tableData.filter(item => {
          return selectedRowKeys.includes(item.fieldCode)
        })
      }
    }

    insertExamPosition(params).then(res => {
      if (res.code === ResponseCode.success) {
        notifyApi.success({
          message: '操作成功'
        })
        hideModal()
        props.insertSuccess && props.insertSuccess()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '设置考试职位字段失败',
        centered: true
      })
    })
  }

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys)
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title="配置职位字段"
        open={ modalVisible }
        width="800px"
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Transfer
          titles={ ['职位字段列表', '已选字段'] }
          dataSource={ tableData }
          targetKeys={ selectedRowKeys }
          rowKey={ (record) => {
            return record.fieldCode
          } }
          showSearch={ true }
          onChange={ onSelectChange }
          filterOption={ (inputValue, item) =>
            item.name.indexOf(inputValue) !== -1
          }
        >
          { ({
               direction,
               filteredItems,
               onItemSelect,
               onItemSelectAll,
               selectedKeys: listSelectedKeys,
               disabled: listDisabled
             }) => {

            return <Table
              size="small"
              scroll={ {
                y: 300
              } }
              rowKey={ (record) => {
                return record.fieldCode
              } }
              style={ { pointerEvents: listDisabled ? 'none' : undefined } }
              pagination={ false }
              dataSource={ filteredItems }
              rowSelection={ {
                getCheckboxProps: (item) => ({
                  disabled: listDisabled || item.disabled
                }),
                onSelectAll (selected, selectedRows) {
                  const treeSelectedKeys = selectedRows
                    .filter((item) => !item.disabled)
                    .map(({ key }) => key)
                  const diffKeys = selected
                    ? difference(treeSelectedKeys, listSelectedKeys)
                    : difference(listSelectedKeys, treeSelectedKeys)
                  onItemSelectAll(diffKeys, selected)
                },
                onSelect ({ key }, selected) {
                  onItemSelect(key, selected)
                },
                selectedRowKeys: listSelectedKeys
              } }
              columns={ columns }
              onRow={ ({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return
                  onItemSelect(key, !listSelectedKeys.includes(key))
                }
              }) }
            ></Table>
          } }
        </Transfer>
      </Modal>
    </div>
  )
})

export default ConfigExamPositionFieldsModal
