import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Empty, Form, Input, Modal, notification, Select, Spin } from 'antd'
import { createReceivingAccount, getOrgList, updateReceivingAccount } from '../../common/request'
import ResponseCode from '../../common/response-code'
import { accountTypeMap } from '../../common/common'

const EditReceivingAccountModal = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const [notifyApi, contextHolder] = notification.useNotification()
  const [modalTitle, setModalTitle] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [accountType, setAccountType] = useState(1)
  const [orgList, setOrgList] = useState([])
  const [accountTypeList] = useState(Object.keys(accountTypeMap).map(key => {
    return {
      label: accountTypeMap[key],
      value: Number(key)
    }
  }))

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getOrgOption()
      if (props.modalType === 'create') {
        setModalTitle('新建收款账户')
      } else {
        setModalTitle('编辑收款账户')

        init()
      }
    }
  }, [modalVisible, props.modalType])

  useEffect(() => {
    if (modalVisible && accountType) {
      let formData

      switch (props.accountItem?.accountType) {
        case 1:
          formData = {
            ...props.accountItem.weiXinAccount
          }
          break
        case 4:
          formData = {
            bank: props.accountItem.bank,
            ...props.accountItem.msBankAccount
          }
          break
        case 5:
          formData = {
            ...props.accountItem.cmbChinaBankAccount
          }
          break
        case 7:
          formData = {
            ...props.accountItem.swiftPassAccount
          }
          break
        default:
          formData = {}
      }

      form.setFieldsValue(formData)
    }
  }, [modalVisible, accountType, props.accountItem, form])

  const init = () => {
    setAccountType(props.accountItem?.accountType)

    let formData = {
      accountName: props.accountItem.name,
      unitName: props.accountItem.unitName,
      accountNumber: props.accountItem.accountNumber,
      accountType: props.accountItem?.accountType
    }

    form.setFieldsValue(formData)
  }

  const getOrgOption = () => {
    getOrgList({}).then(res => {
      if (res.code === ResponseCode.success && res.data) {
        setOrgList(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch(() => {
      Modal.error({
        title: '网络错误',
        content: '查询机构列表失败',
        centered: true
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const getParams = (fields) => {
    const params = {
      accountName: fields.accountName,
      unitName: fields.unitName,
      accountNumber: fields.accountNumber,
      accountType: fields.accountType,
      orgId: fields.orgId
    }

    switch (fields.accountType) {
      case 1:
        params.weiXinAccount = {
          appId: fields.appId,
          appSecret: fields.appSecret,
          mchId: fields.mchId,
          appV3Secret: fields.appV3Secret
        }
        break
      case 4:
        params.bank = fields.bank
        params.msBankAccount = {
          merchantNo: fields.merchantNo,
          platformId: fields.platformId,
          platformName: fields.platformName,
          publicKeyName: fields.publicKeyName,
          privateKeyName: fields.privateKeyName,
          privateKeyPwd: fields.privateKeyPwd,
          notifyUrl: fields.notifyUrl
        }
        break
      case 5:
        params.cmbChinaBankAccount = {
          merId: fields.merId,
          merName: fields.merName,
          termId: fields.termId,
          userId: fields.userId
        }
        break
      case 7:
        params.swiftPassAccount = {
          mchId: fields.mchId,
          appId: fields.appId,
          userPrivateKey: fields.userPrivateKey,
          userPublicKey: fields.userPublicKey,
          platformPublicKey: fields.platformPublicKey,
          notifyUrl: fields.notifyUrl
        }
        break
    }

    if (props.modalType === 'edit') {
      params.id = props.accountItem.accountId
    }

    return params
  }

  const getRequest = (fields) => {
    let request
    const params = getParams(fields)

    if (props.modalType === 'create') {
      request = createReceivingAccount(params)
    } else {
      request = updateReceivingAccount(params)
    }

    return request
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)

      getRequest(fields).then(res => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notifyApi.success({
            message: '操作成功'
          })
          hideModal()
          props.insertSuccess && props.insertSuccess()
        } else {
          Modal.error({
            title: '错误',
            content: res.msg,
            centered: true
          })
        }
      }).catch(() => {
        setLoading(false)
        Modal.error({
          title: '网络错误',
          content: '保存收款账户失败',
          centered: true
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const accountTypeChange = (key) => {
    setAccountType(key)
  }

  const getAccountChannelFormItem = (type) => {
    let content

    switch (type) {
      case 1:
        content = (<>
          <Form.Item
            label={ '公众号账号ID' }
            name={ 'appId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入公众号账号ID' }></Input>
          </Form.Item>
          <Form.Item
            label={ '商户号' }
            name={ 'mchId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户号' }></Input>
          </Form.Item>
          <Form.Item
            label={ '支付密钥' }
            name={ 'appSecret' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入支付密钥' }></Input>
          </Form.Item>
          <Form.Item
            label={ 'V3支付密钥' }
            name={ 'appV3Secret' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入V3支付密钥' }></Input>
          </Form.Item>
        </>)
        break
      case 4:
        content = (<>
          <Form.Item
            label={ '开户行' }
            name={ 'bank' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入开户行' }></Input>
          </Form.Item>
          <Form.Item
            label={ '商户名称' }
            name={ 'platformName' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户名称' }></Input>
          </Form.Item>
          <Form.Item
            label={ '商户号' }
            name={ 'platformId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户号' }></Input>
          </Form.Item>
          <Form.Item
            label={ '签约编码' }
            name={ 'merchantNo' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入签约编码' }></Input>
          </Form.Item>
          <Form.Item
            label={ '公钥名' }
            name={ 'publicKeyName' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入公钥名' }></Input>
          </Form.Item>
          <Form.Item
            label={ '私钥名' }
            name={ 'privateKeyName' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入私钥名' }></Input>
          </Form.Item>
          <Form.Item
            label={ '私钥密码' }
            name={ 'privateKeyPwd' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入私钥密码' }></Input>
          </Form.Item>
          <Form.Item
            label={ '通知地址' }
            name={ 'notifyUrl' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入通知地址' }></Input>
          </Form.Item>
        </>)
        break
      case 5:
        content = (<>
          <Form.Item
            label={ '商户号' }
            name={ 'merId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户号' }></Input>
          </Form.Item>
          <Form.Item
            label={ '商户名称' }
            name={ 'merName' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户名称' }></Input>
          </Form.Item>
          <Form.Item
            label={ '终端号' }
            name={ 'termId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入终端号' }></Input>
          </Form.Item>
          <Form.Item
            label={ '收银员' }
            name={ 'userId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入收银员' }></Input>
          </Form.Item>
        </>)
        break
      case 7:
        content = (<>
          <Form.Item
            label={ '商户号' }
            name={ 'mchId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户号' }></Input>
          </Form.Item>
          <Form.Item
            label={ '公众号ID' }
            name={ 'appId' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入公众号ID' }></Input>
          </Form.Item>
          <Form.Item
            label={ '商户私钥' }
            name={ 'userPrivateKey' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户私钥' }></Input>
          </Form.Item>
          <Form.Item
            label={ '商户公钥' }
            name={ 'userPublicKey' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入商户公钥' }></Input>
          </Form.Item>
          <Form.Item
            label={ '平台公钥' }
            name={ 'platformPublicKey' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入平台公钥' }></Input>
          </Form.Item>
          <Form.Item
            label={ '回调通知' }
            name={ 'notifyUrl' }
            rules={ [{ required: true }] }
          >
            <Input placeholder={ '请输入回调通知' }></Input>
          </Form.Item>
        </>)
        break
      default:
        content = <Empty description={ '暂不支持' }/>
    }

    return content
  }

  const getOrgOptions = (list) => {
    return list.map(orgItem => {
      return <Select.Option key={ orgItem.id } value={ orgItem.id }>{ orgItem.name }</Select.Option>
    })
  }

  return (
    <div ref={ ref }>
      { contextHolder }
      <Modal
        title={ modalTitle }
        open={ modalVisible }
        confirmLoading={ loading }
        onOk={ onOk }
        onCancel={ hideModal }
      >
        <Spin spinning={ loading }>
          <Form
            form={ form }
            labelCol={ {
              span: 8
            } }
            wrapperCol={ {
              span: 12
            } }
            initialValues={ {
              accountType: 1,
              orgId: 'system'
            } }
          >
            <Form.Item
              label={ '所属机构' }
              name={ 'orgId' }
              rules={ [{ required: true }] }
            >
              <Select>
                <Select.Option value={ 'system' }>中正公考</Select.Option>
                { getOrgOptions(orgList) }
              </Select>
            </Form.Item>
            <Form.Item
              label={ '账号名称' }
              name={ 'accountName' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入账户名称' }></Input>
            </Form.Item>
            <Form.Item
              label={ '单位名称' }
              name={ 'unitName' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入单位名称' }></Input>
            </Form.Item>
            <Form.Item
              label={ '账户号' }
              name={ 'accountNumber' }
              rules={ [{ required: true }] }
            >
              <Input placeholder={ '请输入账户号' }></Input>
            </Form.Item>
            <Form.Item
              label={ '账号类型' }
              name={ 'accountType' }
              rules={ [{ required: true }] }
            >
              <Select options={accountTypeList} onChange={ accountTypeChange }>
                {/*<Select.Option value={ 1 }>微信</Select.Option>*/}
                {/*<Select.Option value={ 2 }>支付宝</Select.Option>*/}
                {/*<Select.Option value={ 4 }>民生银行</Select.Option>*/}
                {/*<Select.Option value={ 5 }>招商银行</Select.Option>*/}
              </Select>
            </Form.Item>
            { getAccountChannelFormItem(accountType) }
          </Form>
        </Spin>
      </Modal>
    </div>
  )
})

export default EditReceivingAccountModal
