import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, DatePicker, Form, Input, Modal, Radio, Select, Space, Table } from 'antd'
import dayjs from '@plugins/local-dayjs'

import SelectExam from '../../components/select-exam/SelectExam'
import ExamApplyUnitList from '../../request/ExamApplyUnitList'
import ExamPositionList from '../../request/ExamPositionList'
import { checkPage } from '../../common/request'
import ResponseCode from '../../common/response-code'
import ExamineeInfoModal from '../../components/examinee/ExamineeInfoModal'
import { examCheckStatus } from '../../common/common'
import { throttle } from '../../plugins/utils'
import DeIdentification from '../../components/DeIdentification'

function ExamCheckManagement () {
  const [form] = Form.useForm()
  const unitCode = Form.useWatch('unitCode', form)
  const examineeInfoModalRef = useRef()
  const [currentEditItem, setCurrentEditItem] = useState({})
  const selectedExam = useSelector(state => state.examInfo.selectedExam)
  const [checkStatus, setCheckStatus] = useState(1)
  const [unitListOption, setUnitListOption] = useState([])
  const [positionListOption, setPositionListOption] = useState([])
  const [tableData, setTableData] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [pageNum, setPageNum] = useState(1)
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      width: 90,
      title: '姓名',
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      title: '报名序号',
      key: 'examineesNumber',
      dataIndex: 'examineesNumber'
    },
    {
      width: 190,
      title: '身份证号',
      key: 'idCard',
      dataIndex: 'idCard',
      render: (record) => {
        return (<DeIdentification value={ record } type={ 'name' }></DeIdentification>)
      }
    },
    {
      width: 90,
      title: '单位代码',
      key: 'unitCode',
      dataIndex: 'unitCode'
    },
    {
      title: '单位名称',
      key: 'unitName',
      dataIndex: 'unitName'
    },
    {
      width: 90,
      title: '职位代码',
      key: 'positionCode',
      dataIndex: 'positionCode'
    },
    {
      title: '职位名称',
      key: 'positionName',
      dataIndex: 'positionName'
    },
    {
      title: '提交时间',
      key: 'submitTime',
      dataIndex: 'submitTime',
      render: (record) => {
        return record ? dayjs(record).format('YYYY-MM-DD HH:mm').toString(): '--'
      }
    },
    {
      title: '审核时间',
      key: 'lastCheckTime',
      dataIndex: 'lastCheckTime',
      render: (record) => {
        return record ? dayjs(record).format('YYYY-MM-DD HH:mm').toString() : '--'
      }
    },
    {
      title: '审核人',
      key: 'checkPerson',
      dataIndex: 'checkPerson'
    },
    {
      width: 100,
      title: '审核状态',
      key: 'saveStatus',
      dataIndex: 'saveStatus',
      fixed: 'right',
      render: (record) => {
        return (<div className={ examCheckStatus[record].className }>{ examCheckStatus[record].label }</div>)
      }
    },
    {
      width: 100,
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      fixed: 'right',
      render: (record, row) => {
        return (
          <Button
            size={ 'small' }
            type={ 'primary' }
            ghost
            onClick={ showExamineeDetail.bind(this, row) }
          >详情</Button>
        )
      }
    }
  ]

  useEffect(() => {
    if (selectedExam.id && unitListOption.length > 0) {
      getTableData()
    }
  }, [selectedExam, checkStatus, unitListOption, pageSize, pageNum])

  const getTableData = () => {
    const searchData = form.getFieldsValue()
    setLoading(true)
    const params = {
      ...searchData,
      unitCodes: searchData.unitCode ? [searchData.unitCode] : unitListOption.filter(unitItem => unitItem.value).map(unitItem => unitItem.value),
      submitStartTime: searchData.submitTimeRange?.length > 0 ? dayjs(searchData.submitTimeRange[0]).format('YYYY-MM-DD HH:mm:ss') : [],
      submitEndTime: searchData.submitTimeRange?.length > 0 ? dayjs(searchData.submitTimeRange[1]).format('YYYY-MM-DD HH:mm:ss') : [],
      checkStartTime: searchData.checkTimeRange?.length > 0 ? dayjs(searchData.checkTimeRange[0]).format('YYYY-MM-DD HH:mm:ss') : [],
      checkEndTime: searchData.checkTimeRange?.length > 0 ? dayjs(searchData.checkTimeRange[1]).format('YYYY-MM-DD HH:mm:ss') : [],
      examId: selectedExam.id,
      orderBy: 'examineesNumber',
      orderType: 'ASC',
      pageNum,
      pageSize,
      saveStatusSet: [checkStatus]
    }
    delete params.unitCode

    checkPage(params).then(res => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setTableData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询审核考生列表失败'
      })
    })
  }

  const showExamineeDetail = (row) => {
    setCurrentEditItem(row)

    examineeInfoModalRef.current.showModal()
  }

  const getApplyUnitList = (unitList) => {
    const initOptions = unitList.map(item => {
      return {
        label: `${ item.unitName }_${ item.unitCode }`,
        value: item.unitCode
      }
    })

    initOptions.unshift({
      label: '全部',
      value: ''
    })

    setUnitListOption(initOptions)
  }

  const getApplyPositionList = (positionList) => {
    setPositionListOption([{
      label: '全部',
      value: ''
    }, ...positionList.map(item => {
      return {
        label: `${ item.positionName }_${ item.positionCode }`,
        value: item.positionCode
      }
    })])
  }

  const checkStatusChange = (e) => {
    setCheckStatus(e.target.value)
    setPageNum(1)
  }

  const paginationChange = (num, size) => {
    setPageSize(size)
    if (size === pageSize) {
      setPageNum(num)
    } else {
      setPageNum(1)
    }
  }

  const unitCodeChange = () => {
    form.setFieldValue('positionCode', '')
  }

  const getExamineeCheckBtnVisible = (statue) => {
    const currentTime = new Date().getTime()
    return statue === 1 || (statue === -1 && (selectedExam.checkStartTime < currentTime && currentTime < selectedExam.checkEndTime))
  }

  return (<>
    <SelectExam>
      <ExamApplyUnitList examId={ selectedExam.id } loadSuccess={ getApplyUnitList }></ExamApplyUnitList>
      <ExamPositionList examId={ selectedExam.id } unitCode={ unitCode } loadSuccess={ getApplyPositionList }></ExamPositionList>

      <Space direction="vertical">
        <Radio.Group value={ checkStatus } buttonStyle="solid" onChange={ checkStatusChange }>
          <Radio.Button value={ 1 }>待审核</Radio.Button>
          <Radio.Button value={ 2 }>审核通过</Radio.Button>
          <Radio.Button value={ -1 }>审核不通过</Radio.Button>
        </Radio.Group>
        <Form
          layout={ 'inline' }
          form={ form }
          initialValues={ {
            unitCode: '',
            positionCode: '',
            name: '',
            idCard: '',
            examineesNumber: '',
            submitTimeRange: [],
            checkTimeRange: []
          } }
          onFieldsChange={ throttle(getTableData, 500) }
        >
          <Space wrap size={ 16 }>
            <Form.Item
              label={ '报考单位' }
              name={ 'unitCode' }>
              <Select
                options={ unitListOption }
                style={ { width: 360 } }
                onChange={ unitCodeChange }></Select>
            </Form.Item>
            <Form.Item
              label={ '报考职位' }
              name={ 'positionCode' } dependencies={ ['unitCode'] }>
              <Select
                disabled={ !form.getFieldValue('unitCode') }
                options={ positionListOption }
                style={ { width: 300 } }></Select>
            </Form.Item>
            <Form.Item
              label={ '姓名' }
              name={ 'name' }
              trigger={ 'onInput' }>
              <Input placeholder={ '请输入姓名' }></Input>
            </Form.Item>
            <Form.Item
              label={ '身份证' }
              name={ 'idCard' }
              trigger={ 'onInput' }>
              <Input placeholder={ '请输入身份证' }></Input>
            </Form.Item>
            <Form.Item
              label={ '报名序号' }
              name={ 'examineesNumber' }
              trigger={ 'onInput' }>
              <Input placeholder={ '请输入报名序号' }></Input>
            </Form.Item>
            <Form.Item
              label={ '提交审核时间' }
              name={ 'submitTimeRange' }>
              <DatePicker.RangePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{ defaultValue: [dayjs('09:00:00', 'HH:mm'), dayjs('18:00:00', 'HH:mm')] }}
                style={ { width: '100%' } }></DatePicker.RangePicker>
            </Form.Item>
            <Form.Item
              label={ '审核时间' }
              name={ 'checkTimeRange' }>
              <DatePicker.RangePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{ defaultValue: [dayjs('09:00:00', 'HH:mm'), dayjs('18:00:00', 'HH:mm')] }}
                style={ { width: '100%' } }></DatePicker.RangePicker>
            </Form.Item>
          </Space>
        </Form>
      </Space>
      <Table
        className={ 'mt-3' }
        loading={ loading }
        scroll={ {
          x: 1660
        } }
        columns={ columns }
        dataSource={ tableData.elements }
        rowKey={ record => record.id }
        pagination={ {
          showTotal: (total) => {
            return `共 ${ total } 条`
          },
          size: 'normal',
          showSizeChanger: true,
          total: tableData.totalElements,
          pageSize: pageSize,
          current: pageNum,
          onChange: paginationChange
        } }
      ></Table>
    </SelectExam>

    <ExamineeInfoModal
      ref={ examineeInfoModalRef }
      examineeItem={ currentEditItem }
      activeTabs={ ['examineeInfo', 'applyFormTable'] }
      examineeCheckable={ getExamineeCheckBtnVisible(checkStatus, selectedExam) }
      uploadList={ getTableData }
      pageType={'check'}
    ></ExamineeInfoModal>
  </>)
}

export default ExamCheckManagement
